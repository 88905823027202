import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { PATH } from "../routes/constants";
import MainLogo from "../images/logo.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import Accordion from "react-bootstrap/Accordion";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import { Link as ScrollLink } from "react-scroll";

function PrivacyPolicy() {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const position = [51.505, -0.09];

  const slideCount = 3;

  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section1" smooth={true} duration={500}>Plans</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section2" smooth={true} duration={500}>Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    FAQS <small></small>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5">
              <Row className="align-items-center sub-content">
                <Col>
                <h2>General FAQs</h2>
    <h3>How can I access my BDS/BDS NExT course content?</h3>
    <p>You can access the well-structured, high-quality audio-visual content on the Cuspyd BDS/BDS NExT app.</p>

    <h3>Do you have a mobile app?</h3>
    <p>Yes, Cuspyd App is available in Play store for android users and soon to be available on app store for iOS users as well.</p>

    <h3>Can I access all my study material through the website?</h3>
    <p>Yes, you can access all your study material from the website, except videos and soft copy of notes.</p>

    <h2>Medical Content FAQs</h2>
    <h3>While answering an MCQ, can I go back and answer the unanswered questions?</h3>
    <p>If you have not clicked the submit button, you can go back to answer the unanswered questions.</p>

    <h3>Can I attempt the MCQs more than once?</h3>
    <p>Yes, you can attempt the MCQs any number of times.</p>

    <h3>Can I get topic-wise MCQs?</h3>
    <p>Yes, you can assess yourself at the end of each topic using our tailor-made multiple-choice questions.</p>

    <h3>Are the questions similar to NEET MDS?</h3>
    <p>Yes, our MCQs are a combination of competitive exam and NEET MDS pattern questions.</p>

    <h3>Do the MCQs contain answers & explanations?</h3>
    <p>Yes, each MCQ has answer & explanation.</p>

    <h3>Do you provide any notes or study material?</h3>
    <p>Yes, we provide soft copy of notes which you can read while or after you watch videos.</p>

    <h2>Technical FAQs</h2>
    <h3>Do you provide a year-wise subscription?</h3>
    <p>Yes, we provide year-wise subscription.</p>

    <h3>Possibility of taking only hard copy notes/selected subject notes?</h3>
    <p>We only provide a soft copy of our notes which you can access through the app itself.</p>

    <h3>Can I get a PDF copy of notes for preparation?</h3>
    <p>We do not share PDF copies. Soft copy of notes are available on the app.</p>

    <div class="contact">
        <p>Email: <a href="mailto:help@cuspyd.com">help@cuspyd.com</a></p>
        <p>Phone: 88775 34567 / 88774 34567</p>
    </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
