import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Nav from "react-bootstrap/Nav";
import { PATH } from "../routes/constants";
import FirstBanner from "../images/videos/Anatomy.mp4";
import SecondBanner from "../images/videos/Bio_Chemistry.mp4";
import ThirdBanner from "../images/videos/DADH.mp4";
import FourthBanner from "../images/videos/Pathology.mp4";
import FifthBanner from "../images/videos/Physiology.mp4";
import MainLogo from "../images/logo.png";
import GooglePlay from "../images/google.svg";
import AppStore from "../images/app-store.svg";
import AboutCuspyd from "../images/abt-Logo.png";
import Courses_1 from "../images/cour_01.png";
import GetInTouch from "../images/get-touch.png";
import Courses_2 from "../images/cour_02.png";
import Courses_3 from "../images/cour_03.png";
import Spotlights_1 from "../images/spot_1.svg";
import Spotlights_2 from "../images/spot_2.svg";
import Spotlights_3 from "../images/spot_3.svg";
import Spotlights_4 from "../images/spot_4.svg";
import Spotlights_5 from "../images/spot_5.svg";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import AOS from "aos";
import ContactUs from "../components/ContactUs";
import Form from "react-bootstrap/Form";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/global.scss";

function Landing() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const position = [51.505, -0.09];
  const slideCount = 6;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <div className="landing-page">
        <div className="custom-carousel">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <video autoPlay muted>
                <source src={FirstBanner} type="video/mp4" />
              </video>
              {/* <span className='testing'>Banner 1</span> */}
            </Carousel.Item>
            <Carousel.Item>
              <video autoPlay muted>
                <source src={SecondBanner} type="video/mp4" />
              </video>
              {/* <span className='testing'>Banner 2</span> */}
            </Carousel.Item>
            <Carousel.Item>
              <video autoPlay muted>
                <source src={ThirdBanner} type="video/mp4" />
              </video>
              {/* <span className='testing'>Banner 3</span> */}
            </Carousel.Item>
            <Carousel.Item>
              <video autoPlay muted>
                <source src={FourthBanner} type="video/mp4" />
              </video>
              {/* <span className='testing'>Banner 4</span> */}
            </Carousel.Item>
            <Carousel.Item>
              <video autoPlay muted>
                <source src={FifthBanner} type="video/mp4" />
              </video>
              {/* <span className='testing'>Banner 5</span> */}
            </Carousel.Item>
            <Carousel.Item>
              <video autoPlay muted>
                <source src={FirstBanner} type="video/mp4" />
              </video>
              {/* <span className='testing'>Banner 6</span> */}
            </Carousel.Item>
          </Carousel>
          <Container>
            <div
              className="custom-carousel__caption"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <h3>Comprehensive Platform for</h3>
              <h2 className="text-uppercase mt-3 pb-3">
                BDS | BDS NExT | NEET MDS
              </h2>
              <div className="mobile-store">
                <a href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd" target="_new"><img height={40} src={GooglePlay} alt="" /></a>
                <a href="https://apps.apple.com/in/app/cuspyd/id6737215378" target="_new"><img height={40} src={AppStore} alt="" /></a>
              </div>
            </div>
          </Container>
        </div>
        <div className="about-cuspyd light-blue-bg py-5">
          <Container>
            <Row className="align-items-center">
              <Col md={6} data-aos="fade-right" data-aos-duration="2000">
                <img height={60} src={AboutCuspyd} alt="" />
              </Col>
              <Col md={6} data-aos="fade-left" data-aos-duration="2000">
                <p>
                  Cuspyd is an exclusive edutech platform for dental students
                  created by dentists, powered by technology to change the way
                  in which dental professionals learn and grow.{" "}
                </p>
                <p>
                  Our mission is to provide students with a comprehensive,
                  innovative, and engaging learning experience that equips them
                  with the knowledge, skills, and confidence they need to
                  succeed in the rapidly evolving field of dentistry.
                </p>
                <Button className="btn-arrow mt-4" variant="primary" href={PATH.ABOUT}>
                  Learn More{" "}
                  <Badge>
                    <i class="bi bi-arrow-right"></i>
                  </Badge>
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="my-5">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <span>Cuspyd's</span>
            <strong>
              Spotlights <small></small>
            </strong>
          </div>
        </Container>
        <div className="spotlights">
          <Container>
            <Row>
              <Col md="6" data-aos="fade-right" data-aos-duration="2000">
                <h1>01</h1>
                <h5>
                  Effective & Dynamic
                  <br /> 3D videos
                </h5>
                <p className="mt-4">
                  With our engaging, lively{" "}
                  <strong>3D visuals and easy-to-follow videos,</strong> you'll
                  be able to quickly and confidently retain the concepts from
                  each topic better & get a headstart on your BDS/BDS NExT
                  preparations. Our expert faculty members present complex
                  medical & dental concepts in a simple and understandable way.
                </p>
              </Col>
              <Col md="6"
                data-aos="fade-left"
                data-aos-duration="2000"
                className="text-end"
              >
                <img height={400} src={Spotlights_1} alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="spotlights gray-bg mt-5 py-5">
          <Container>
            <Row>
              <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }} data-aos="fade-right" data-aos-duration="2000">
                <img height={400} src={Spotlights_2} alt="" />
              </Col>
              <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }} data-aos="fade-left" data-aos-duration="2000">
                <h1>02</h1>
                <h5>Structured Notes</h5>
                <p className="mt-4">
                  Maximize your exam preparation with our comprehensive{" "}
                  <strong>notes covering all the key concepts</strong> and
                  previously asked questions you need to know for the exam.Our
                  point wise notes, having tables, flowcharts, integrated
                  mnemonics and easy-to-draw illustrations, give you an edge on
                  exam day.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="spotlights mt-5">
          <Container>
            <Row>
              <Col md="6" data-aos="fade-right" data-aos-duration="2000">
                <h1>03</h1>
                <h5>Comprehensive Qbank</h5>
                <p className="mt-4">
                  Boost your{" "}
                  <strong>confidence with our extensive collection</strong> of
                  previously asked questions from various university exams. Use
                  our collection of previously asked questions to take your
                  preparation to the next level. It is the perfect resource for
                  BDS and BDS NExT exam preparation.
                </p>
              </Col>
              <Col md="6"
                data-aos="fade-left"
                data-aos-duration="2000"
                className="text-end"
              >
                <img height={400} src={Spotlights_3} alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="spotlights gray-bg mt-5 py-5">
          <Container>
            <Row>
              <Col xs={{ order: 2 }} md={{ span: 6, order: 1 }} data-aos="fade-right" data-aos-duration="2000">
                <img height={400} src={Spotlights_4} alt="" />
              </Col>
              <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }} data-aos="fade-left" data-aos-duration="2000">
                <h1>04</h1>
                <h5>Multiple Choice Questions</h5>
                <p className="mt-4">
                  Self assess{" "}
                  <strong>your knowledge by answering MCQs curated</strong>{" "}
                  exclusively from standard textbooks and previous competitive
                  exams after every topic for your success in NEET MDS.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="spotlights mt-5">
          <Container>
            <Row>
              <Col md="6" data-aos="fade-right" data-aos-duration="2000">
                <h1>05</h1>
                <h5>Experienced Faculty</h5>
                <p className="mt-4">
                  Within our platform, you can benefit from the guidance of
                  proficient faculty members,{" "}
                  <strong>well-versed in their subjects.</strong> Their
                  commitment to delivering top-quality classes is paralleled by
                  their devotion to mentoring you, assuring thorough resolution
                  of all your doubts. This approach ensures a holistic learning
                  experience.
                </p>
              </Col>
              <Col md="6"
                data-aos="fade-left"
                data-aos-duration="2000"
                className="text-end"
              >
                <img height={400} src={Spotlights_5} alt="" />
              </Col>
            </Row>
          </Container>
        </div>





    <div className="gray-bg mt-5 py-5 only-mobile" name="section1">
    <Container>
    <div
            className="section-title mb-5"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <span>Our</span>
            <strong>
              Plans <small></small>
            </strong>
          </div>
    <Carousel>
      <Carousel.Item>
      <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Powerpack{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>PLAN A (Powerpack)- 13,999</h6>
                      <strong>Videos</strong>
                      <ol>
                        <li>
                          Video lectures covering 100% of BDS syllabus -Theory &
                          Practical of all subjects.
                        </li>
                        <li>
                          Complete & detailed Video Lectures for University,
                          NEET-MDS, and future BDS-NExT exams.
                        </li>
                        <li>
                          All Lectures by expert faculty members in the
                          subjects.
                        </li>
                      </ol>
                      <strong>Qbank</strong>
                      <ol>
                        <li>
                          Collection of previous years questions from various
                          universities in India for all subjects.
                        </li>
                        <li>
                          Divided according to Chapter, topic, type of question,
                          and year in which it was asked.
                        </li>
                        <li>
                          Option to bookmark tough or important questions.
                        </li>
                      </ol>
                      <strong>Multiple Choice Questions</strong>
                      <ol>
                        <li>Multiple-choice questions for all subjects.</li>
                        <li>
                          Includes: previously asked questions after every topic
                          & NEET MDS pattern-based questions
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                      <strong>Notes</strong>
                      <ol>
                        <li>
                          Exam-oriented comprehensive notes for{" "}
                          <b>all subjects.</b>
                        </li>
                        <li>
                          Eligibility to access soft copy of notes - read while
                          you watch videos or after you watch them.
                        </li>
                        <li>
                          Eligibility to buy the hard copy of notes – all
                          subjects (delivered to your address)
                        </li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹13999/-</strong>
                  <small className="d-block mt-2">₹16999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-check2"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Watch & learn Plan{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow2}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show2}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose2}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>
                        PLAN C (Watch & learn Plan)- <small>9999</small> 7999
                      </h6>
                      <strong>Videos Only</strong>
                      <ol>
                        <li>
                          Video lectures covering 100% of BDS syllabus -{" "}
                          <b>Theory & Practical of all subjects.</b>
                        </li>
                        <li>
                          Complete & Detailed Video Lectures for University,
                          NEET-MDS, and future BDS-NExT exams.
                        </li>
                        <li>
                          All Lectures by expert faculty members in the
                          subjects.
                        </li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose2}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹7999/-</strong>
                  <small className="d-block mt-2">₹9999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-check2"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-x-lg"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-x-lg"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-x-lg"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Exam Day plan{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow3}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show3}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose3}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>
                        PLAN D (Exam Day plan)- <small>6999</small> 4999
                      </h6>
                      <strong>Qbank</strong>
                      <ol>
                        <li>
                          Collection of previous years’ questions from various
                          universities in India <b>for all subjects.</b>
                        </li>
                        <li>
                          Divided according to Chapter, topic, type of question,
                          and year in which it was asked.
                        </li>
                        <li>
                          Option to bookmark tough or important questions.
                        </li>
                      </ol>
                      <strong>Multiple Choice Questions</strong>
                      <ol>
                        <li>
                          Multiple-choice questions <b>for all subjects.</b>
                        </li>
                        <li>
                          Includes: previously asked questions after every topic
                          & NEET MDS pattern-based questions.
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                      <strong>Notes</strong>
                      <ol>
                        <li>
                          Exam-oriented comprehensive notes{" "}
                          <b>for all subjects.</b>
                        </li>
                        <li>
                          Eligibility to access soft copy of notes - read while
                          you watch videos orafter you watch them.
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose3}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹4999/-</strong>
                  <small className="d-block mt-2">₹6999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-x-lg"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Make your own plan{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow1}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show1}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose1}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>
                        PLAN B (Make your own plan)- <small>3999</small> 2999
                        each subject
                      </h6>
                      <strong>Videos</strong>
                      <ol>
                        <li>
                          Video lectures covering 100% of BDS syllabus -{" "}
                          <b>
                            Theory & Practical of up to 3 subjects of your
                            choice
                          </b>
                        </li>
                        <li>
                          Complete & detailed Video Lectures for University,
                          NEET-MDS, and future BDS-NExT exams.
                        </li>
                        <li>
                          All Lectures by expert faculty members in the
                          subjects.
                        </li>
                      </ol>
                      <strong>Qbank</strong>
                      <ol>
                        <li>
                          Collection of previous years’ questions from various
                          universities in India{" "}
                          <b>for up to 3 subjects of your choice</b>
                        </li>
                        <li>
                          Divided according to Chapter, topic, type of question,
                          and year in which it was asked.
                        </li>
                        <li>
                          Option to bookmark tough or important questions.
                        </li>
                      </ol>
                      <strong>Multiple Choice Questions</strong>
                      <ol>
                        <li>
                          Multiple-choice questions{" "}
                          <strong>for up to 3 subjects of your choice.</strong>
                        </li>
                        <li>
                          Includes: previously asked questions after every topic
                          & NEET MDS pattern-based questions.
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                      <strong>Notes</strong>
                      <ol>
                        <li>
                          Exam-oriented comprehensive notes{" "}
                          <b>for up to 3 subjects of your choice.</b>
                        </li>
                        <li>
                          Eligibility to access soft copy of notes - read while
                          you watch videos or after you watch them.
                        </li>
                        <li>
                          Eligibility to buy the hard copy of notes – all
                          subjects (delivered to your address).
                        </li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose1}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹2999/-</strong>
                  <small className="d-block mt-2">₹3999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-check2"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
      </Carousel.Item>
    </Carousel>
    </Container>
    </div>



        <div className="gray-bg mt-5 py-5 only-desktop" name="section1">
          <Container>
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <span>Our</span>
            <strong>
              Plans <small></small>
            </strong>
          </div>
          <div
            className="row our-plans mt-5"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Powerpack{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>PLAN A (Powerpack)- 13,999</h6>
                      <strong>Videos</strong>
                      <ol>
                        <li>
                          Video lectures covering 100% of BDS syllabus -Theory &
                          Practical of all subjects.
                        </li>
                        <li>
                          Complete & detailed Video Lectures for University,
                          NEET-MDS, and future BDS-NExT exams.
                        </li>
                        <li>
                          All Lectures by expert faculty members in the
                          subjects.
                        </li>
                      </ol>
                      <strong>Qbank</strong>
                      <ol>
                        <li>
                          Collection of previous years questions from various
                          universities in India for all subjects.
                        </li>
                        <li>
                          Divided according to Chapter, topic, type of question,
                          and year in which it was asked.
                        </li>
                        <li>
                          Option to bookmark tough or important questions.
                        </li>
                      </ol>
                      <strong>Multiple Choice Questions</strong>
                      <ol>
                        <li>Multiple-choice questions for all subjects.</li>
                        <li>
                          Includes: previously asked questions after every topic
                          & NEET MDS pattern-based questions
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                      <strong>Notes</strong>
                      <ol>
                        <li>
                          Exam-oriented comprehensive notes for{" "}
                          <b>all subjects.</b>
                        </li>
                        <li>
                          Eligibility to access soft copy of notes - read while
                          you watch videos or after you watch them.
                        </li>
                        <li>
                          Eligibility to buy the hard copy of notes – all
                          subjects (delivered to your address)
                        </li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹13999/-</strong>
                  <small className="d-block mt-2">₹16999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-check2"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Watch & learn Plan{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow2}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show2}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose2}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>
                        PLAN C (Watch & learn Plan)- <small>9999</small> 7999
                      </h6>
                      <strong>Videos Only</strong>
                      <ol>
                        <li>
                          Video lectures covering 100% of BDS syllabus -{" "}
                          <b>Theory & Practical of all subjects.</b>
                        </li>
                        <li>
                          Complete & Detailed Video Lectures for University,
                          NEET-MDS, and future BDS-NExT exams.
                        </li>
                        <li>
                          All Lectures by expert faculty members in the
                          subjects.
                        </li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose2}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹7999/-</strong>
                  <small className="d-block mt-2">₹9999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-check2"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-x-lg"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-x-lg"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-x-lg"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Exam Day plan{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow3}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show3}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose3}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>
                        PLAN D (Exam Day plan)- <small>6999</small> 4999
                      </h6>
                      <strong>Qbank</strong>
                      <ol>
                        <li>
                          Collection of previous years’ questions from various
                          universities in India <b>for all subjects.</b>
                        </li>
                        <li>
                          Divided according to Chapter, topic, type of question,
                          and year in which it was asked.
                        </li>
                        <li>
                          Option to bookmark tough or important questions.
                        </li>
                      </ol>
                      <strong>Multiple Choice Questions</strong>
                      <ol>
                        <li>
                          Multiple-choice questions <b>for all subjects.</b>
                        </li>
                        <li>
                          Includes: previously asked questions after every topic
                          & NEET MDS pattern-based questions.
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                      <strong>Notes</strong>
                      <ol>
                        <li>
                          Exam-oriented comprehensive notes{" "}
                          <b>for all subjects.</b>
                        </li>
                        <li>
                          Eligibility to access soft copy of notes - read while
                          you watch videos orafter you watch them.
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose3}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹4999/-</strong>
                  <small className="d-block mt-2">₹6999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-x-lg"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="our-plans__card">
                <div className="list px-4 py-4">
                  <span className="d-block mb-3">
                    <small>
                      Make your own plan{" "}
                      <Button
                        className="info-icon"
                        variant="primary"
                        onClick={handleShow1}
                      >
                        <i class="bi bi-info-circle-fill"></i>
                      </Button>
                    </small>
                  </span>
                  <Modal
                    className="custom-model"
                    show={show1}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleClose1}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Information - <small className="tagline">This plan is valid for 1-year subscription.</small></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <h6>
                        PLAN B (Make your own plan)- <small>3999</small> 2999
                        each subject
                      </h6>
                      <strong>Videos</strong>
                      <ol>
                        <li>
                          Video lectures covering 100% of BDS syllabus -{" "}
                          <b>
                            Theory & Practical of up to 3 subjects of your
                            choice
                          </b>
                        </li>
                        <li>
                          Complete & detailed Video Lectures for University,
                          NEET-MDS, and future BDS-NExT exams.
                        </li>
                        <li>
                          All Lectures by expert faculty members in the
                          subjects.
                        </li>
                      </ol>
                      <strong>Qbank</strong>
                      <ol>
                        <li>
                          Collection of previous years’ questions from various
                          universities in India{" "}
                          <b>for up to 3 subjects of your choice</b>
                        </li>
                        <li>
                          Divided according to Chapter, topic, type of question,
                          and year in which it was asked.
                        </li>
                        <li>
                          Option to bookmark tough or important questions.
                        </li>
                      </ol>
                      <strong>Multiple Choice Questions</strong>
                      <ol>
                        <li>
                          Multiple-choice questions{" "}
                          <strong>for up to 3 subjects of your choice.</strong>
                        </li>
                        <li>
                          Includes: previously asked questions after every topic
                          & NEET MDS pattern-based questions.
                        </li>
                        <li>Tailor-made MCQs with to-the-point explanations</li>
                      </ol>
                      <strong>Notes</strong>
                      <ol>
                        <li>
                          Exam-oriented comprehensive notes{" "}
                          <b>for up to 3 subjects of your choice.</b>
                        </li>
                        <li>
                          Eligibility to access soft copy of notes - read while
                          you watch videos or after you watch them.
                        </li>
                        <li>
                          Eligibility to buy the hard copy of notes – all
                          subjects (delivered to your address).
                        </li>
                      </ol>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose1}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <strong className="d-block">₹2999/-</strong>
                  <small className="d-block mt-2">₹3999/-</small>
                  <div className="item mt-5">
                    <em>
                      <i class="bi bi-check2"></i> Videos
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> MCQ’s
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Qbank
                    </em>
                    <em>
                      <i class="bi bi-check2"></i> Notes
                    </em>
                  </div>
                </div>
                <div className="d-grid">
                  <Button className="mt-5" size="lg" variant="primary">
                    Learn More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        </div>
        <div className="courses gray-bg mt-5 py-5">
          <Container>
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <span>Our</span>
              <strong>
                Courses <small></small>
              </strong>
            </div>
            <div
              className="row mt-5"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="col-md-4 col-xs-12">
                <div className="courses__card white-bg">
                  <div className="header bg1 text-center">
                    <img src={Courses_1} alt="" />
                  </div>
                  <div className="body px-4 py-4">
                    <strong>UG CURRICULUM</strong>
                    <p className="mt-3">
                      Shine in your classes & feel more prepared for university
                      exams.{" "}
                    </p>
                    <p>
                      <strong>Cuspyd’s UG CURRICULUM course</strong> would be a
                      perfect fit for you.{" "}
                    </p>
                    <p className="mb-0">
                      Stream 3D animated video lectures, practice MCQs and
                      browse through notes of all topics at the click of a
                      button.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="courses__card white-bg">
                  <div className="header bg2 text-center">
                    <img src={Courses_2} alt="" />
                  </div>
                  <span className="badge">Coming Soon</span>
                  <div className="body px-4 py-4">
                    <strong>BDS NExT</strong>
                    <p className="mt-3">
                      Thinking about how{" "}
                      <strong>
                        BDS NExT will affect your undergraduate studies?
                      </strong>{" "}
                      Cuspyd has you covered.{" "}
                    </p>
                    <p className="mb-0">
                      With a unique approach to clinical application of
                      concepts, using flashcards and in-depth notes and MCQs
                      right from first year BDS, we prepare you for anything
                      that lies ahead in your dental journey.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="courses__card white-bg">
                  <div className="header bg3 text-center">
                    <img src={Courses_3} alt="" />
                  </div>
                  <span className="badge">Coming Soon</span>
                  <div className="body px-4 py-4">
                    <strong>NEET MDS</strong>
                    <p className="mt-3">
                      Are you still looking to crack one of India's most
                      competitive <strong>NEET MDS exams?</strong> It requires
                      solid preparation and a focused mindset.
                    </p>
                    <p className="mb-0">
                      Our upcoming course is designed specifically keeping in
                      mind the requirements of the exam and tailor- made for MDS
                      aspirants.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <ContactUs/>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default Landing;
