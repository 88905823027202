import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import sub01 from "../images/blog/sub/05/01.png";
import sub02 from "../images/blog/sub/05/02.png";
import sub03 from "../images/blog/sub/05/03.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>Part 3: CLINICAL CAREER OPTIONS AFTER BDS – PRIVATE SECTOR</h2>
<p>Dentistry offers a wide range of clinical career options that can suit the interests and goals of the dental practitioner. Here are a few options which you can consider for working in the private sector:</p>
<ul>
  <li>Dental Associate</li>
  <li>Setting up your clinic</li>
  <li>Working in a dental chain</li>
</ul>

<h3>1. DENTAL ASSOCIATE</h3>
<img className="py-4" src={sub01} alt="" />
<p>You can work as a dental associate in a clinic after BDS and practice general dentistry. It is a great opportunity to learn and refine your clinical skills as well as to establish yourself as a clinician. You will be expected to observe and learn initially and subsequently, you’ll be moving ahead with more advanced procedures. This experience will help you in the future if you plan to set up your own clinic since you’ll be learning clinic management skills as well as soft skills to converse with the patients.</p>

<h4>THINGS TO KEEP IN MIND –</h4>
<ul>
  <li>Join a practice similar to your future plans to get the maximum benefit of experience and exposure.</li>
  <li>Choose a mentor who will help you in growing your skills.</li>
  <li>Look for a clinic nearby or having a similar type of location to your future practice.</li>
</ul>

<h3>2. SETTING UP YOUR OWN CLINIC</h3>
<img className="py-4" src={sub02} alt="" />
<p>You’ll get an opportunity to be your own boss and independently function your clinical practice. The initial setup cost ranges from 5-10 lakhs, depending on the area and the type of practice. You can practice general dentistry in your clinic and call MDS consultants for advanced procedures.</p>

<h4>THINGS TO KEEP IN MIND</h4>
<ul>
  <li>Consider the location, demand for dentists in the area and the cost aspect before setting up a clinic.</li>
  <li>Determine the type of your clinical practice.</li>
  <li>It takes time for a clinic to get established, but with patience and hard work, your practice will bloom eventually.</li>
</ul>

<h3>3. WORKING IN A DENTAL CHAIN</h3>
<img className="py-4" src={sub03} alt="" />
<p>There are many well-established dental chains with branches in various cities of India. This provides you an opportunity to be associated with a well-known chain of dental clinics and helps in networking for further career growth. Such dental chains have standardized practices, policies, and procedures, which can help in ensuring the consistency and quality of care.</p>

<h4>THINGS TO KEEP IN MIND</h4>
<ul>
  <li>Working in a dental chain may limit your autonomy about patient care, treatment options since the major decisions are made by the corporate management.</li>
  <li>These chains are primarily focused on generating profits, which can create pressure to prioritize financial interests over patient needs or quality of care.</li>
  <li>Most of these dental chains have rigid scheduling and treatment protocols which can limit your flexibility as a dentist.</li>
</ul>

<h2>CONCLUSION –</h2>
<p>The private sector provides an opportunity for improved healthcare facilities, enhanced career growth, and professional networking, which will further establish your clinical skills and practice.</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
