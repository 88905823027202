import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import sub01 from "../images/blog/sub/02/01.png";
import sub02 from "../images/blog/sub/02/02.png";
import sub03 from "../images/blog/sub/02/03.png";
import sub04 from "../images/blog/sub/02/04.png";
import sub05 from "../images/blog/sub/02/05.png";
import sub06 from "../images/blog/sub/02/06.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5 sub-content">
              <Row>
                <Col>
                  <h2>NON CLINICAL CAREER OPTIONS AFTER BDS PART</h2>
                  <h3>1: HEALTH SECTOR</h3>
                  <p>Who said that you are only limited to the clinical practice options after graduating as BDS? If you want to step out of the clinical zone and still want to do something related to your field, there are several non-clinical job options available. Your knowledge and skills can help you get jobs in several government and private health sectors.</p>
                  <h3>NON CLINICAL JOBS IN HEALTH SECTOR –</h3>
                  <ol>
                    <li>Clinical Research</li>
                    <li>Forensic Odontology</li>
                    <li>Pharmacovigilance</li>
                    <li>Medical Coding</li>
                    <li>ICMR</li>
                  </ol>
                  <h3>1. CLINICAL RESEARCH</h3>
                  <img className="py-4" src={sub01} alt="" />
                  <h4>What is Clinical Research?</h4>
                  <ul>
                    <li>Clinical research is a branch of healthcare science that checks the safety and effectiveness of novel treatments, medications, medical devices, and diagnostic techniques when administered to patients.</li>
                    <li>It is done by running stringent clinical trials, monitoring patients’ progress, and measuring the efficacy of a new drug. </li>
                    <li>It is a rapidly growing branch in the healthcare sector.</li>
                  </ul>
                  <h4>Job opportunities :</h4>
                  <ul>
                    <li>Clinical Research Associate (CRA)</li>
                    <li>Clinical Research Managers (CRM)</li>
                    <li>Clinical Research Coordinator (CRO)</li>
                    <li>Counselors</li>
                    <li>Clinical research data specialist</li>
                    <li>Research pharmacist</li>
                    <li>Clinical project manager</li>
                    <li>Medical/scientific writer</li>
                    <li>Marketing and communications professional</li>
                  </ul>
                  <h4>Duration : </h4>
                  <ul>
                    <li>Post Graduate Diploma – 1 year</li>
                    <li>Certification – 6 months</li>
                  </ul>
                  <h4>PROS:</h4>
                  <ul>
                    <li>Development of research skills.</li>
                    <li>Contribution in advancements to improve patient care.</li>
                  </ul>
                  <h4>CONS:</h4>
                  <ul>
                    <li>Time consuming and resource sensitive.</li>
                    <li>Needs secure funding.</li>
                  </ul>
                  <h3>2. FORENSIC ODONTOLOGY</h3>
                  <img className="py-4" src={sub02} alt="" />
                  <img className="py-4" src={sub03} alt="" />
                  <h4>What is forensic odontology?</h4>
                  <p>Forensic Dentistry is a field of dental sciences and forensic science that deals with tracing and examining dental evidence to solve legal investigations.</p>
                  <h4>Job Opportunities  :</h4>
                  <ul>
                    <li>Forensic Consultant</li>
                    <li>Forensic Odontologist</li>
                    <li>Forensic Scientist</li>
                    <li>Lecturer</li>
                  </ul>
                  <h4>Where you’ll be working :</h4>
                  <ol>
                    <li>Police and Investigating Departments</li>
                    <li>Forensic Labs</li>
                    <li>Government and Private Hospitals</li>
                    <li>Law Firms</li>
                    <li>Academic Institutions</li>
                    <li>Research Centers</li>
                  </ol>
                  <h4>DURATION :</h4>
                  <ul>
                    <li>MSc in forensic odontology – 2 years</li>
                    <li>Certificate courses – 6 months</li>
                  </ul>
                  <h4>PROS:</h4>
                  <ul>
                    <li>Supporting in solving criminal investigations</li>
                    <li>Helping in identification of victims of mass disasters</li>
                  </ul>
                  <h4>CONS: </h4>
                  <ul>
                    <li>Legal challenges and exposure to disturbing content</li>
                    <li>Limited career and research opportunities</li>
                  </ul>
                  <h3>3. PHARMACOVIGILANCE</h3>
                  <img className="py-4" src={sub04} alt="" />
                  <h4>What is Pharmacovigilance?</h4>
                  <ul>
                    <li>Pharmacovigilance includes understanding the assessment, detection, and prevention of adverse effects or any other drug-related problems.</li>
                    <li>It is a continuous process accepted for the safety evaluation of drugs.</li>
                  </ul>
                  <h4>Job Opportunities :</h4>
                  <ul>
                      <li>Pharmaceutical Companies</li>
                      <li>Knowledge Process Outsourcing (KPO)</li>
                      <li>Clinical Research Organizations</li>
                      <li>Medical Colleges &amp; Reputed Hospitals</li>
                      <li>Regulatory Agencies like CDSCO and DCG</li>
                      <li>Pharmacovigilant Officers</li>
                    </ul>
                  <h4>Duration : </h4>
                  <ul>
                    <li>Diploma – 1 to 2 years</li>
                    <li>Certificate courses – 6 months</li>
                  </ul>
                  <h4>PROS: </h4>
                  <ul>
                    <li>Advancement of scientific knowledge and improvement of patient care.</li>
                    <li>High demand due to fast growth of the pharmaceutical industry.</li>
                  </ul>
                  <h4>CONS:</h4>
                  <ul>
                    <li>Needs navigation of complex regulations and guidelines.</li>
                    <li>Demands a high level of responsibility and attention to detail.</li>
                  </ul>
                  <h3>4. MEDICAL CODING</h3>
                  <img className="py-4" src={sub05} alt="" />
                  <h4>What is medical coding?</h4>
                  <ul>
                    <li>Medical coding is a translation process where, data from a patient’s medical records are converted into industry-standard medical code, which is accepted globally everywhere.</li>
                    <li>They are also used to collect statistics to track information on a specific disease, medicines, treatment, etc.</li>
                    <li>These codes are also used to process insurance claims with third party  payers.</li>
                  </ul>
                  <h4>Job Opportunities –</h4>
                  <ul>
                    <li>Certified Medical Coder (CMC)</li>
                    <li>Certified Coding Associate (CCA)</li>
                    <li>Certified Coding Specialist (CCS)</li>
                    <li>Certified Coding Specialist Physician-Based (CCS-P)</li>
                    <li>Certified Professional Coder (CPC-A)</li>
                  </ul>
                  <h4>Duration : <span>1-3 years of training</span></h4>
                  <h4>PROS:</h4>
                  <ul>
                    <li>Stable job market and high demand for dental professionals.</li>
                    <li>Flexibility in work  timings with remote job options.</li>
                  </ul>
                  <h4>CONS:</h4>
                  <ul>
                    <li>Requires long periods of desk job.</li>
                    <li>Needs a high volume of data entry and attention to detail.</li>
                  </ul>
                  <h3>5. INDIAN COUNCIL OF MEDICAL RESEARCH (ICMR)</h3>
                  <img className="py-4" src={sub06} alt="" />
                  <h4>What is ICMR?</h4>
                  <ul>
                    <li>The ICMR is an organization that is funded by the Government of India.</li>
                    <li>It focuses on conducting research and contributing to improving current healthcare facilities and trends.</li>
                  </ul>
                  <h4>Job Opportunities –</h4>
                  <ul>
                    <li>Project Researcher</li>
                    <li>Project Researcher Assistant</li>
                    <li>Scientist 'D' (Dental)</li>
                    <li>Project Field Investigator</li>
                    <li>Project Field Assistant</li>
                    <li>Consultant</li>
                  </ul>
                  <h4>Duration :</h4>
                  <ul>
                    <li>Certification - 4 to 8 weeks</li>
                  </ul>
                  <h4>PROS:</h4>
                  <ul>
                    <li>Government supported research.</li>
                    <li>Opportunities for networking and collaboration.</li>
                  </ul>
                  <h4>CONS:</h4>
                  <ul>
                    <li>High competition for funding.</li>
                    <li>Strict deadlines to complete the research process</li>
                  </ul>
                  <h4>CONCLUSION</h4>
                  <p>With the right training and experience, dentists can have a fulfilling career in the non clinical sector by contributing their knowledge and skills to improve the overall quality of healthcare.</p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
