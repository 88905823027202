import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import sub01 from "../images/blog/sub/03/01.png";
import sub02 from "../images/blog/sub/03/02.png";
import sub03 from "../images/blog/sub/03/03.png";
import sub04 from "../images/blog/sub/03/04.png";
import sub05 from "../images/blog/sub/03/05.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5 sub-content">
              <Row>
                <Col>
                    <h2>PART 2 - CLINICAL CAREER OPTIONS AFTER BDS IN GOVERNMENT SECTOR</h2>
                    <p>You can contribute to the public health through serving the community through government services in India. These job options provide job security, better work-life balance and opportunities for professional growth in reputed sectors.</p>
                    <h3>CLINICAL JOB OPTIONS IN GOVERNMENT SECTOR</h3>
                    <ol>
                      <li>Project Researcher</li>
                      <li>Project Researcher Assistant</li>
                      <li>Scientist 'D' (Dental)</li>
                      <li>Project Field Investigator</li>
                      <li>Project Field Assistant</li>
                      <li>Consultant</li>
                    </ol>
                    <h4>1. ARMY DENTAL CORPS</h4>
                    <img className="py-4" src={sub01} alt="" />
                    <ol>
                        <li><strong>Eligibility:</strong> A BDS degree from an institution recognized by DCI with minimum marks of 55% in final year.</li>
                        <li><strong>Selection procedure:</strong> NEET MDS followed by interview and medical examination.</li>
                        <li><strong>Age Limit:</strong> BDS (30 yrs), MDS (35 yrs)</li>
                        <li><strong>Duration:</strong> Short Service Commission for 5 years, which can be upgraded into Permanent Commission after clearing an exam 2 years after joining the service.</li>
                      </ol>
                    <h4>THINGS TO KEEP IN MIND –</h4>
                    <ol>
                      <li>It will provide you with job security, stable salary, and the respect which is carried by an army uniform.</li>
                      <li>You will be posted to various locations after every 2-3 years.</li>
                      <li>It will provide you an opportunity to serve our nation’s soldiers.</li>
                    </ol>
                    <h4>2. CENTRAL AND STATE GOVERNMENT JOBS</h4>
                    <img className="py-4" src={sub02} alt="" />
                    <ol>
                      <li><strong>Eligibility:</strong> Any qualified dentist from an institution recognized by DCI who fulfills the specified eligibility criteria.</li>
                      <li><strong>Selection procedure:</strong> Through either subjective/objective exams, interviews or both.</li>
                      <li><strong>Notification:</strong> Through newspaper advertisements and updates on the state government websites regarding the job openings.</li>
                      <li>
                      <ul>
                        <li>Positions available –
                          <ul>
                            <li>As a Dental Officer</li>
                            <li>As a faculty in government institutions like Government Dental Colleges, AIIMS and PGI.</li>
                          </ul>
                        </li>
                      </ul>
                      </li>
                      <li><strong>Workplace</strong> – PHCs and government hospitals</li>
                    </ol>
                    <h4>1. INDIAN RAILWAYS</h4>
                    <img className="py-4" src={sub03} alt="" />
                    <ol>
                        <li><strong>Eligibility:</strong> A BDS degree from an institution recognized by DCI with at least 3 years of work experience. Doctors with MDS degree don’t require work experience.</li>
                        <li><strong>Selection process:</strong> Through interview of shortlisted candidates.</li>
                        <li><strong>Notification:</strong> Through newspaper advertisements and the official website. Only limited number of job openings are announced from time to time.</li>
                        <li><strong>Positions available:</strong> Part-time, full-time, and contractual. The available positions will be divided into zones such as Southern, Central, Eastern, and Western.</li>
                        <li><strong>Workplace:</strong> You will be assigned the duty at railway hospitals.</li>
                      </ol>
                    <h4>2. ESIC or Employees State Insurance Corporation</h4>
                    <img className="py-4" src={sub04} alt="" />
                    <ol>
                      <li><strong>Job specifications:</strong> Provide dental care to patients covered under Employees State Insurance Scheme.</li>
                      <li><strong>Requirements:</strong> Requires strong clinical and academic credentials.</li>
                      <li><strong>Notification:</strong> Through newspaper advertisements and the official website.</li>
                      <li><strong>Selection process:</strong> Through interview of shortlisted candidates.</li>
                      <li><strong>Workplace:</strong> ESI hospital and colleges.</li>
                    </ol>
                    <h4>3. ECHS or Ex-Servicemen Contributory Health Scheme</h4>
                    <img className="py-4" src={sub05} alt="" />
                    <ol>
                        <li><strong>Job specifications:</strong> Dental care for veterans and ex-servicemen of the defense forces.</li>
                        <li><strong>Requirements:</strong> BDS degree from an institution recognized by DCI and a work experience of more than 3 years in some places.</li>
                        <li><strong>Notification:</strong> Through newspaper advertisements and the official website.</li>
                        <li><strong>Selection process:</strong> Through interview of shortlisted candidates.</li>
                        <li><strong>Workplace:</strong> ECHS Polyclinics on a contractual basis.</li>
                      </ol>
                      <h4>CONCLUSION –</h4>
<p>Working as a dentist in the government sector comes with its own benefits of job security, stable income, and other incentives and benefits. Since the number of vacancies are limited, one must be vigilant about the latest notifications and job openings.</p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
