import React from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "./constants";
import Landing from "../components/Landing";
import Blog from "../components/Blog";
import ClinicalCareerOptions from "../components/ClinicalCareerOptions";
import NonClinicalCareer from "../components/NonClinicalCareer";
import BdsGovernmentSector from "../components/BdsGovernmentSector";
import BdsAdministrative from "../components/BdsAdministrative";
import BdsPrivateSector from "../components/BdsPrivateSector";
import BdsAbroad from "../components/BdsAbroad";
import BoonDentalStudents from "../components/BoonDentalStudents";
import MdsFirstYear from "../components/MdsFirstYear";
import MentalHealthDentalStudents from "../components/MentalHealthDentalStudents";
import SurvivalGuide from "../components/SurvivalGuide";
import AboutUs from "../components/AboutUs";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsandConditions from "../components/TermsandConditions";
import FAQS from "../components/Faqs";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path={PATH.HOME} element={<Landing />} />
      <Route path={PATH.ABOUT} element={<AboutUs />} />
      <Route path={PATH.BLOG} element={<Blog />} />
      <Route path={PATH.ClinicalCareerOptions} element={<ClinicalCareerOptions />} />
      <Route path={PATH.NonClinicalCareer} element={<NonClinicalCareer />} />
      <Route path={PATH.BdsGovernmentSector} element={<BdsGovernmentSector />} />
      <Route path={PATH.BdsAdministrative} element={<BdsAdministrative />} />
      <Route path={PATH.BdsPrivateSector} element={<BdsPrivateSector />} />
      <Route path={PATH.BdsAbroad} element={<BdsAbroad />} />
      <Route path={PATH.BoonDentalStudents} element={<BoonDentalStudents />} />
      <Route path={PATH.MdsFirstYear} element={<MdsFirstYear />} />
      <Route path={PATH.MentalHealthDentalStudents} element={<MentalHealthDentalStudents />} />
      <Route path={PATH.SurvivalGuide} element={<SurvivalGuide />} />
      <Route path={PATH.PrivacyPolicy} element={<PrivacyPolicy />} />
      <Route path={PATH.TermsandConditions} element={<TermsandConditions />} />
      <Route path={PATH.FAQS} element={<FAQS />} />
    </Routes>
  );
};

export default RouteList;
