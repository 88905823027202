import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import sub01 from "../images/blog/sub/06/01.png";
import sub02 from "../images/blog/sub/06/02.png";
import sub03 from "../images/blog/sub/06/03.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>PART 3: CLINICAL CAREER OPTIONS AFTER BDS ABROAD</h2>
<p>Practicing abroad after BDS in India has an advantage of better career opportunities and higher salaries. Many developed countries have a shortage of dentists and they actively recruit qualified professionals from other countries.</p>
<img className="py-4" src={sub01} alt="" />
<h3>United States of America (USA)</h3>
<ul>
  <li><strong>Current selection procedure:</strong> Integrated National Board Dental Examination (INBDE)</li>
  <li><strong>Earlier selection procedure:</strong> NBDE Part 1 & Part 2</li>
  <li><strong>Duration of Doctor of Dental Surgery Course (DDS):</strong> 2 years</li>
  <li><strong>Initial Investment:</strong> 1.2 – 1.8 crores</li>
  <li><strong>Note:</strong> Dentists in the United States are one of the highest-paid professionals.</li>
</ul>

<h3>United Kingdom (UK)</h3>
<ul>
  <li><strong>Selection Procedure:</strong> Overseas Registration exam (ORE)</li>
  <li><strong>Part 1 & Part 2 exams:</strong> Held in London</li>
  <li><strong>Eligibility:</strong> Eligible to practice after getting registered with the General Dental Council (GDC)</li>
  <li><strong>Other exams to clear:</strong> IELTS Language exam</li>
</ul>

<h3>Canada</h3>
<ul>
  <li><strong>Conducted by:</strong> National Dental Examining Board of Canada (NDEB)</li>
  <li><strong>NDEB Equivalency process:</strong> Comprises the following assessments:
    <ul>
      <li>Assessment of Fundamental Knowledge – 6 hrs</li>
      <li>Assessment of Clinical Judgement – 6 hrs</li>
      <li>Assessment of Clinical Skills – 2 days (6hrs each)</li>
      <li>Objective Structured Clinical Examination (OSCE)</li>
    </ul>
  </li>
</ul>

<h3>Australia</h3>
<ul>
  <li><strong>Selection Procedure:</strong> Australian Dental Council (ADC) exam</li>
  <li><strong>Stages of ADC Exams:</strong>
    <ul>
      <li>Initial Assessment</li>
      <li>Written Exam</li>
      <li>Practical Exam</li>
    </ul>
  </li>
  <li>After clearing these stages, you can apply to the Dental Board of Australia (DBA) to grant you the license for a general dentist.</li>
  <li><strong>Additional requirement:</strong> IELTS exam must be cleared.</li>
</ul>

<h3>Gulf Countries</h3>
<ul>
  <li><strong>Selection Procedure:</strong> Dubai Health Authority (DHA) exam held at various locations.</li>
  <li><strong>Health authority:</strong> Present in Abu Dhabi.</li>
  <li><strong>Regulated by:</strong> Ministry of Health (MOH)</li>
  <li><strong>Number of attempts:</strong> 3</li>
</ul>

<h3>New Zealand</h3>
<ul>
  <li><strong>New Zealand Dentist Registration Examination:</strong>
    <ul>
      <li>Written phase</li>
      <li>Clinical phase</li>
    </ul>
  </li>
  <li><strong>New Zealand Dental Specialist Registration Examination:</strong>
    <ul>
      <li>Written phase</li>
      <li>Clinical phase</li>
      <li>Case presentation session</li>
      <li>Oral examination phase</li>
      <li>Research skill examination</li>
    </ul>
  </li>
</ul>

<h3>OTHER CLINICAL OPTIONS ABROAD</h3>
<img className="py-4" src={sub02} alt="" />
<img className="py-4" src={sub03} alt="" />
<ul>
  <li>Dental Assistant</li>
  <li>Dental Hygienist</li>
  <li>Cosmetology</li>
  <li>Botox</li>
  <li>Hair Transplant</li>
  <li>Various diploma, certificate, and fellowship courses are available for developing the abovementioned skills.</li>
</ul>

<h2>CONCLUSION –</h2>
<p>Establishing your dental practice abroad gives you exposure to newer technologies and cultures, which can further enhance your skills and your standard of living. Although the initial establishment takes an extensive investment of time and money, it has proved to be rewarding over a period of time.</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
