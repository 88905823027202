import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import Blog01 from "../images/blog/01.png";
import Blog02 from "../images/blog/02.png";
import Blog03 from "../images/blog/03.png";
import Blog04 from "../images/blog/04.png";
import Blog05 from "../images/blog/05.png";
import Blog06 from "../images/blog/06.png";
import Blog07 from "../images/blog/07.png";
import Blog08 from "../images/blog/08.png";
import Blog09 from "../images/blog/09.png";
import Blog10 from "../images/blog/10.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>IMPORTANCE OF MENTAL HEALTH FOR DENTAL STUDENTS</h2>

<p>Performing well in your dental career is important but so is your health, both physical and mental. There are several challenges that a dental student faces during the 5 years of BDS:</p>
<ul>
  <li>Completing clinical quota on time.</li>
  <li>Keeping up with the academic curriculum and being regular in college.</li>
  <li>Finishing assignments on time and meeting deadlines.</li>
  <li>Performing well in internal as well as university exams.</li>
  <li>Maintaining the required attendance in college.</li>
  <li>Falling sick during the course is even more difficult to take care of if you live in a hostel.</li>
</ul>
<p>Instead of getting caught up in the mayhem, here are the 5 things you can do when you feel your mental health is taking a toll.</p>

<h3>GET SOME FRESH AIR</h3>
<p>Sometimes, continuously studying at your desk, especially during exams, can get monotonous and exhausting. Getting fresh air has been attributed to the enhancement of the oxygen levels in the body, which can lead to better performance during work as well as fewer chances of anxiety and depression. So, the next time your energy levels start to feel stagnant, go out for a walk in nature or ride your bicycle and just breathe in the positivity.</p>

<h3>COMMUNICATE WITH YOUR FRIENDS & FAMILY</h3>
<p>It’s okay to feel alone and isolated sometimes in life. Regardless, you should always remember that people are there for you. Sharing your problems with a trusted friend or a family member can boost you up instantly instead of struggling alone with it. If you don’t feel like sharing your current thoughts with someone, you can practice journaling, which can prove to be a great outlet for the jumbled thoughts in your mind.</p>

<h3>GET ACTIVE</h3>
<p>Exercising releases endorphins in our body, which can improve your sense of control, coping ability, and self-esteem. It can reduce skeletal muscle tension, which helps you feel more relaxed. For a dental student, it is sometimes difficult to take out time from your packed-up schedule. However, it is recommended that you take out at least 30 minutes to keep your body active.</p>

<h3>REWARD YOURSELF</h3>
<p>Your goals become more exciting when you have a reward in front of you. Your reward doesn’t have to be something big but the little things you enjoy in life. You can make a list of all the things you relish and enjoy and assign them as rewards after the accomplishment of your goals. They can be:</p>
<ul>
  <li>Eating your favourite dessert</li>
  <li>Watching a movie</li>
  <li>Going out with friends</li>
</ul>
<p>So, the next time you study the whole week with dedication and sincerity, give yourself a little treat because you deserve it.</p>

<h3>ONE STEP AT A TIME</h3>
<p>Don’t be so hard on yourself by worrying too much about the past or future. Rather, utilize your present moment for the best. If you feel that your clinical skills weren’t satisfactory in the past, you can always improve on them. A journey of 100 miles begins with a single step. Don’t take too much stress about the future aspects. We often come across people with negative comments about our profession. You don’t have to pay attention to their words.</p>

<h3>CONCLUSION</h3>
<p>Sometimes it is important to take a pause and catch a breath to prevent yourself from going into a state of breakdown. With a healthy mind and a healthy body, you can deliver high-quality treatment to your patients and become a better professional.</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
