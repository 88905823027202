import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import Blog01 from "../images/blog/01.png";
import Blog02 from "../images/blog/02.png";
import Blog03 from "../images/blog/03.png";
import Blog04 from "../images/blog/04.png";
import Blog05 from "../images/blog/05.png";
import Blog06 from "../images/blog/06.png";
import Blog07 from "../images/blog/07.png";
import Blog08 from "../images/blog/08.png";
import Blog09 from "../images/blog/09.png";
import Blog10 from "../images/blog/10.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import { Link as ScrollLink } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href={PATH.HOME}>Plans</Nav.Link>
                  <Nav.Link href={PATH.HOME}>Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    Blogs <small></small>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5">
              <Row>
                <Col md={4}>
                  <a href={PATH.ClinicalCareerOptions}>
                  <div className="blog-post">
                    <img src={Blog01} alt="Cuspyd" />
                    <div className="description">
                      <span>
                        Clinical Career Options After Bds In Academics
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
                <Col md={4}>
                <a href={PATH.NonClinicalCareer}>
                  <div className="blog-post">
                    <img src={Blog02} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      Non Clinical Career Options After Bds Part
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
                <Col md={4}>
                <a href={PATH.BdsGovernmentSector}>
                  <div className="blog-post">
                    <img src={Blog03} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      Clinical Career Options After Bds In Government Sector
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={4}>
                <a href={PATH.BdsAdministrative}>
                  <div className="blog-post">
                    <img src={Blog04} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      Non Clinical Career Options After Bds – Administrative And Corporate Sector
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
                <Col md={4}>
                <a href={PATH.BdsPrivateSector}>
                  <div className="blog-post">
                    <img src={Blog05} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      Clinical Career Options After Bds – Private Sector
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
                <Col md={4}>
                <a href={PATH.BdsAbroad}>
                  <div className="blog-post">
                    <img src={Blog06} alt="Cuspyd" />
                    <div className="description">
                      <span>
                        Clinical Career Options After Bds Abroad
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
              </Row>
              <Row className="mt-4">
              <Col md={4}>
              <a href={PATH.BoonDentalStudents}>
                  <div className="blog-post">
                    <img src={Blog07} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      How Digital Education Is A Boon For Dental Students
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
                <Col md={4}>
                <a href={PATH.MdsFirstYear}>
                  <div className="blog-post">
                    <img src={Blog08} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      How To Prepare For Neet Mds From First-year Bds?
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
                <Col md={4}>
                <a href={PATH.MentalHealthDentalStudents}>
                  <div className="blog-post">
                    <img src={Blog09} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      Importance Of Mental Health For Dental Students
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
              </Row>
              <Row className="mt-4">
              <Col md="4">
              <a href={PATH.SurvivalGuide}>
                  <div className="blog-post">
                    <img src={Blog10} alt="Cuspyd" />
                    <div className="description">
                      <span>
                      From School To Bds College: Survival Guide For 1st Year Students
                      </span>
                      <small>Post : Aug 11th 23</small>
                    </div>
                  </div>
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
