import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { PATH } from "../routes/constants";
import MainLogo from "../images/logo.png";
import Accordion from "react-bootstrap/Accordion";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import { Link as ScrollLink } from "react-scroll";

function TermsConditions() {
  const [index, setIndex] = useState(0);

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;

  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section1" smooth={true} duration={500}>Plans</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section2" smooth={true} duration={500}>Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    Terms & Conditions <small></small>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5">
              <Row className="align-items-center">
                <Col>
                <strong>The following terms and conditions constitute an agreement between the user and Cuspyd:</strong>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="acr_01">
                      1. User Terms And Conditions
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>Dentedu Pvt Ltd is hereafter referred to as “Cuspyd”, “Company”, “we,” or “us”.</li>
                          <li>The term “Platforms” includes Cuspyd website, mobile application and any services offered by Cuspyd.</li>
                          <li>The term “User” includes any individual who is using Cuspyd platforms and is hereafter referred to as “User”, “individual” or “you” and is applicable in all the clauses of the terms and conditions, unless specified otherwise.</li>
                          <li>Registering on Cuspyd platforms signifies that
                            <ol>
                              <li>You agree to all the terms and conditions listed herein</li>
                              <li>You agree to the information disclosure practices in our “Use Policy”, which is incorporated herein by reference</li>
                              <li>You agree to comply with applicable laws, governed and interpreted by the country of service.</li>
                            </ol>
                          </li>
                          <li>Cuspyd platforms are offered for use on an “as is” basis, without warranties.</li>
                          <li>When registering an account on behalf of an individual or entity other than yourself, you represent that you are authorized by such individual or entity to accept these Terms of Use on such individual’s or entity’s behalf.</li>
                          <li>We respect and adhere to copyright law and expect our users to do the same.</li>
                          <li>Your account with Cuspyd is associated primarily with your email id or mobile number. The account is to be used by the owner of the registered email id or mobile number only.</li>
                          <li>Your account with Cuspyd is subject to being blocked or terminated, without warning, under the following circumstances:
                            <ol>
                              <li>Evidence of, or reasonable grounds to believe that there is infringement of copyright laws by Registered Users or account holders</li>
                              <li>Sharing your account details, including email and password with any unauthorized user</li>
                              <li>Accessing your account across multiple devices, either simultaneously, or consecutively.</li>
                            </ol>
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        2. User Obligation
                      </Accordion.Header>
                      <Accordion.Body>
                      <ol>
                          <li>You may use the Website/App for lawful purposes only and in accordance with the user policy.</li>
                          <li>You promise that:
                            <ol>
                              <li>You are of legal age to form a binding contract with Cuspyd.</li>
                              <li>You will not use the Website/app in any way that violates any applicable local or international laws and regulations.</li>
                              <li>You will not send, knowingly receive, upload, download, use or re-use any material which does not comply with the ‘Content Standards.’</li>
                              <li>You will not impersonate or attempt to impersonate Cuspyd, an employee of Cuspyd, another user, or any other person or entity.</li>
                              <li>You will not co-brand or connect the Website or hyperlink to it without the express prior written permission of Cuspyd.</li>
                              <li>You will maintain strict confidentiality of your User Information.</li>
                              <li>You will not allow another person to use your User Information to access the Services.</li>
                              <li>You will not indulge in any damages or losses that may be incurred or suffered as a result of any activities occurring under your usage.</li>
                              <li>You agree to immediately notify Cuspyd, in writing by email, of any unauthorized use of your User Information or any other breach of security.</li>
                              <li>Cuspyd is not and shall not be liable for any harm arising from or relating to the theft of your User Information, your disclosure of your User Information, or the use of your User Information by another person or entity.</li>
                              <li>Any attempt to obtain unauthorized access or to exceed authorized access to the Website, Services, App, or Collective Content shall be considered trespass, and computer fraud and abuse, punishable under national, provincial, state, and federal laws.</li>
                              <li>Cuspyd hereby notifies you that any or all communication with this Website can and will be monitored, captured, recorded, and transmitted to the authorities as deemed necessary by Cuspyd, under its sole discretion and without further notice.</li>
                            </ol>
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        3. Content Standards
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>These content standards apply to all User Contributions and the use of Interactive Services.</li>
                          <li>User Contributions must, in their entirety, comply with all applicable local and international laws and regulations.</li>
                          <li>Without being limited by the terms below, User Contributions to the use of Interactive Services must not:
                            <ol>
                              <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                              <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
                              <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or rights of any third-party.</li>
                              <li>Infringe the legal rights of others, or contain any material that could give rise to any civil or criminal liability under applicable local and international laws.</li>
                              <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
                              <li>Cause annoyance, inconvenience, or needless anxiety, or be likely to upset, embarrass, alarm, or annoy any other person.</li>
                              <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization.</li>
                              <li>Indulge in commercial activities or sales.</li>
                              <li>Deceive or misrepresent that they emanate from, or are endorsed by us, or any other person or entity.</li>

                            </ol>
                          </li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>4. User Content</Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>Cuspyd does not claim ownership of any Content that you post on or through the Service, Website or App.</li>
                          <li>Instead, you hereby grant to Cuspyd, in perpetuity, a non-exclusive, fully paid and royalty-free, transferable, sub-licensable (through several tiers), worldwide license to use, reproduce, view, communicate to the public by any means, print, copy, (whether onto hard disk or other media), edit, translate, perform and display (publicly or otherwise), redistribute, modify, adapt, make, sell, offer to sell, transmit, distribute, license, transfer, stream, broadcast, create derivative works from, and otherwise use and exploit the Content that you post on or through the Service, Website or App, subject to the Service’s Privacy Policy.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                      5. Type Of Content And Services
                      </Accordion.Header>
                      <Accordion.Body>
                      <ol>
                        <li>Cuspyd is solely an educational platform for doctors.</li>
                        <li>We do not intend to, and we do not provide any diagnostic, investigational, consultation, or treatment advice whatsoever.</li>
                        <li>The information provided on the Website and the App is intended solely for educational purposes only.</li>
                        <li>Any other use is not permitted under the terms and conditions and would constitute a violation of the terms and conditions.</li>
                        <li>The ever-changing evidence in the field of medicine is always a challenge to learn. The Website or App content provided by us for learning attempts to cover such advances in the field of medicine.</li>
                        <li>The content provided by Cuspyd cannot be held liable for the purposes of healthcare delivery, since this content is intended for only educational services.</li>
                        <li>The learning from Cuspyd content on the Website or App is not intended to replace a doctor’s intellectual ability and decision-making in any instance, and we are not liable for any such acts.</li>
                        <li>Please read the disclaimers section for further information on use.</li>
                      </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        6. App License
                      </Accordion.Header>
                      <Accordion.Body>
                      <ol>
                        <li>Scope of License:
                        <ol>
                            <li>The license granted to you for our Licensed App by the App Provider is non-transferable.</li>
                            <li>The Licensed App is limited for use on any device that you own or control and as permitted by the App Usage Rules.</li>
                            <li>This license does not allow you to use the Licensed App on any device that you do not own or control.</li>
                            <li>You may not distribute or make the Licensed App available over a network where it could be used by multiple devices at the same time.</li>
                            <li>You may not rent, lease, lend, sell, redistribute, or sublicense the Licensed App.</li>
                            <li>You may not copy, decompile, reverse engineer, disassemble, or attempt to derive the source code of the website/app, modify, or create derivative works of the licensed website/app, any updates, or any part thereof.</li>
                            <li>Any attempt to do so constitutes a violation of the rights of the website/app provider and its licensors. If you breach this restriction, you may be subject to lawful actions.</li>
                          </ol>
                        </li>
                        <li>The terms and conditions of the license are applicable to any upgrades provided by App Provider, that may replace and/or supplement the original Product, unless such upgrade is accompanied by a separate license; in which case the terms and conditions of the separate license would be applicable.</li>
                        <li>Consent for Technical Data Collection
                          <ol>
                            <li>You agree that website/app Provider may collect and use technical data and related information, including but not limited to, technical information about your device, system, app software, and peripherals, that is gathered periodically to facilitate software updates, product support and other services to you, as needed, related to the Licensed App.</li>
                            <li>Providers may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</li>
                          </ol>
                        </li>
                        <li>Termination of License:
                          <ol>
                            <li>The license is effective until terminated by you or Cuspyd.</li>
                            <li>Your rights under this license will be terminated automatically and without notice from Cuspyd, if you fail to comply with any one or more terms of this license.</li>
                            <li>Upon termination of the license, you shall cease to use the Licensed App, and dispose of all copies of the content material, full or partial, of the Licensed App.</li>
                          </ol>
                        </li>
                        <li>Third Party Materials: Terms of Use
                          <ol>
                            <li>The Licensed App may enable access to App Providers, third party services and web sites, collectively and individually.</li>
                            <li>Use of the Services may require Internet access, which you accept as additional terms of service.</li>
                          </ol>
                        </li>
                        <li>Third Party Services: Terms of Use
                        <ol>
                          <li>You understand that by using any one or more of the Services, you may encounter content that may be deemed offensive, indecent, or objectionable, which content may or may not be identified as having explicit language, and that the results of any search or entering of a particular URL may automatically and unintentionally generate links or references to objectionable material.</li>
                          <li>You agree to use the Services at your sole risk and understand that Cuspyd does not have any liability for content that you may find offensive, indecent, or objectionable.</li>
                          <li>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only.</li>
                          <li>We have no control over the contents of those sites or resources and accept no responsibility for them, or for any loss or damage that may arise from your use of them.</li>
                          <li>If you decide to access any third-party websites linked to the Website, you do so entirely at your own risk and subject to the terms and conditions of use for such third-party websites.</li>
                        </ol>
                        </li>
                      </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        7. Subscription Terms And Conditions
                      </Accordion.Header>
                      <Accordion.Body>
                      <ol>
                        <li>Cuspyd provides you with access to the content modules and services on its App and / or Website, for a limited period of time, in accordance with your subscribed plan, for your use on a single device at a time.</li>
                        <li>Cuspyd grants you a non-exclusive, non-sub licensable, non- transferable license and the right to use licensed content from the App and/or Website, subject to your payment of the appropriate subscription fee for the services you subscribe to, and your agreement to, and compliance with, the terms and conditions set forth in this agreement.</li>
                      </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        8. Services Terms And Conditions
                      </Accordion.Header>
                      <Accordion.Body>
                      <ol>
                        <li>Subject to these Terms of Service, a user will be granted a limited, non-exclusive, revocable, non-transferable, and non-sublicensable right to access that portion of the Website/app applicable to the plan type.</li>
                        <li>By agreeing to grant such access, Cuspyd does not obligate itself to maintain the Website/app or to maintain it in its present form.</li>
                        <li>Cuspyd may upgrade, modify, change, or enhance the Services and convert a user account/plan to a new version thereof at any time at its sole discretion, to the extent that this is not detrimental to the user’s use of the Services and on reasonable prior notice to the user, unless the change is of critical business importance or is outside the control of Cuspyd. In such cases, we will explain the reason for the changes as soon as is reasonably practicable.</li>
                        <li>Users agree to abide by the rules or regulations that Cuspyd publishes with respect to the conduct of the users of the Website/app. The rules and regulations are hereby incorporated into these Terms of Service by this reference.</li>
                        <li>Cuspyd reserves the right to deny a user and/or otherwise Authorized End User access to the Website/app at its sole discretion if a user and/or otherwise Authorized End User has failed to abide by these Terms of Service or appears likely to do so.</li>
                        <li>User accepts that Cuspyd, at its sole discretion, may, but has no obligation to, monitor the Services or any portion thereof and/or oversee compliance with these Terms of Service.</li>
                        <li>User promises, acknowledges, and agrees upon:
                        <ol>
                            <li>Access privileges will not be transferred to any third parties.</li>
                            <li>You will not access, store, distribute, or transmit any Viruses.</li>
                            <li>You will comply with all applicable laws and regulations with respect to the use of the Services.</li>
                            <li>You will not rent, lease, sublicense, re-sell, distribute, transfer, copy, or modify the Services or any component thereof.</li>
                            <li>You will not translate, decompile, or create or attempt to create, by reverse engineering or otherwise, the source code from the object code made available hereunder.</li>
                            <li>You will not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit the Services or any portion thereof.</li>
                            <li>You will not delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Website or contained in the Services.</li>
                            <li>You are solely responsible for acquiring, installing, operating, and maintaining the hardware and software environment, network connections, and telecommunication links necessary to access and use the Services.</li>
                            <li>You will not use the Services in any manner, or in connection with any content, data, hardware, software, or other materials that infringe upon or violate any patent, copyright, trade secret, trademark, or other intellectual property rights of any third party, or that constitutes defamation, libel, invasion of privacy, or violation of any right of publicity or other third-party rights, or that is threatening, harassing, or malicious.</li>
                            <li>If you are a Multi-User, you promise to obtain any, and all, consents required by law, including, but not limited to:
                            <ol className="alpha-list">
                                <li>Consent to transfer personally identifiable information of your co-User(s), and your representative(s), and provide such information to us.</li>
                                <li>Consent to compile reports as to the usage of the Services by your co-Users and provide such reports to you.</li>
                                <li>Any other consent that may be required in order for us to provide the Services to you and your co-Users.</li>
                                <li>You promise to obtain necessary consents from all relevant bodies, including your institution, Hospital, and from other applicable individuals, if necessary.</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        9. Disclaimers
                      </Accordion.Header>
                      <Accordion.Body>
                      <ol>
                        <li>No Diagnostic Service
                          <ol>
                            <li>The app is primarily an educational tool and is not intended to serve the following needs: as a diagnostic service; as a confirmatory service to provide certainty in diagnosis; to select, guide, or promote therapy of medical conditions; for use in hazardous or mission-critical circumstances, or for uses requiring fail-safe performance; or in situations where failure could lead to death or personal injury. These uses are collectively deemed “unauthorized purposes”.</li>
                            <li>Because the app has not been designed, intended, or authorized for such unauthorized purposes, you shall not use the website, services or app for such purposes or under such circumstances.</li>
                            <li>You further acknowledge that the use of Cuspyd’s website, services, and app for such unauthorized purposes may constitute a violation of laws applicable to the practice of medicine or other health professions (s).</li>
                          </ol>
                        </li>
                        <li>No Warranty You acknowledge that you rely on the content obtained or used by you through the website, services or app, solely at your own risk. Please read the section on No Warranties, mentioned in these terms and conditions.</li>
                        <li>Live Sessions do not constitute expert advice
                        <ol>
                            <li>Live sessions, talks, or discussions are meant for educational purposes only.</li>
                            <li>Any use of such resources as expert advice will be at your own risk.</li>
                            <li>You acknowledge that you are not engaging in the practice of medicine nor requesting medical services of any kind, or intending to create any kind of User relationship, or provide medical consultation by using the content from the live sessions.</li>
                            <li>Cuspyd and the doctors engaged in live sessions, talks, or discussions cannot be held liable for any losses or damages that occur as a result of the use of such content for unauthorized purposes.</li>
                          </ol>
                        </li>
                      </ol>
                      <h5 className="bg-title">Privacy</h5>
                      <ol>
                        <li>We collect several types of information about visitors and/or users of our Services. We may collect this information either directly when you provide it to us, or automatically, as you navigate through the Websites or App. We collect and process your personal data when you
                        <ol className="alpha-list">
                            <li>Register for a Service</li>
                            <li>Create a user account</li>
                            <li>Subscribe to a service or plan</li>
                            <li>Submit feedback</li>
                            <li>Enter a contest</li>
                            <li>Fill out a survey</li>
                            <li>Send us a communication</li>
                          </ol>
                        </li>
                        <li>When ordering products or services on the Websites /app, you may also be asked to provide your credit/debit card number and other payment-related information.</li>
                        <li>Depending upon the activity, some of the information requested is mandatory, in order to use the Services. If you do not provide data when needed for a particular activity, you may not be able to engage in that activity.</li>
                        <li>We also collect your Internet Protocol (“IP”) address. We use your IP address to diagnose problems with our servers and/or software, to administer our Services, and to gather demographic information.</li>
                        <li>We may collect certain information that does not by itself identify a specific individual. Such information tells us about your equipment, browsing actions and the resources that you access and use through the Services, such as your operating system and browser type.</li>
                        <li>We use analytics and similar services to help us to deliver better and more personalized service to users by enabling us to estimate usage patterns, customize our Services according to your individual preferences, and speed up your searches.</li>
                        <li>When you download our App and use our Services, we automatically collect information on the type of device you use and the operating system version, so we know the best version of the application to provide you.</li>
                        <li>We do not ask you for, access, or track any location-based information from your mobile device at any time while downloading or using our website/App or Services.</li>
                        <li>When you contact us for customer support, we may collect additional information to resolve your issue.</li>
                        <li>Except as required by law, Cuspyd will maintain the confidentiality of all user communications, which contain personal user information and which are transmitted directly to www.Cuspyd.com.</li>
                        <li>Read our Privacy Policy for information and notices concerning Cuspyd collection and use of your personal information.</li>
                        <li>By clicking on the “Agree”, “Confirm”, “Proceed” or “Accept” button, or accessing or using the Website, Services, or App, or by downloading any content from or through the Website, Services, or App, you acknowledge and agree to the provisions of the Privacy Policy and affirm that the Privacy Policy forms a part of these Terms and Conditions.</li>
                        <li>By providing your mobile number to Cuspyd, you opt-in and consent to receive message(s) relevant to the app, which will override DND status if any.</li>
                        <li>By providing your email ID, you opt-in and consent to receive relevant newsletters or information until and unless you unsubscribe.</li>
                      </ol>
                      <h5 className="bg-title">Linking To Social Media Features</h5>
                      <ol>
                        <li>You may link to our social media homepage, provided you do so in a way that is fair, legal, and does not damage our reputation or take advantage of it, or cast us in a false or misleading light; but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part.</li>
                        <li>The Website/app may provide you with certain social media features that enable you to:
                          <ol className="alpha-list">
                            <li>Link from your own or certain third-party websites/app to certain content on the Website/app</li>
                            <li>Send e-mails or other communications with certain content or links to specific content on the Website/app</li>
                            <li>Cause limited portions of content on the Website/app to be displayed or appear to be displayed on your own or certain third-party websites/app</li>
                          </ol>
                        </li>
                        <li>You may use these features solely as they are provided by us, and solely with respect to the content they are displayed with, and otherwise in accordance with any additional terms and conditions we provide with respect to such features. Subject to the below terms, you must not:
                        <ol className="alpha-list">
                            <li>Establish a link from any website/app that is not owned by you.</li>
                            <li>Cause the Website/app or portions of them to be displayed, or appear to be displayed by, for example, framing, deep linking, or in-line linking, on any other site/app.</li>
                            <li>Link to any part of the Website/app other than the specified.</li>
                            <li>Otherwise, take any action with respect to the materials on the Website/app that is inconsistent with any other provision of this Policy.</li>
                          </ol>
                        </li>
                        <li>The website/app from which you are linking, or on which you make certain content accessible, must comply in all respects with the Content Standards set by us.</li>
                        <li>You agree to cooperate with us in causing any unauthorized framing or linking to cease immediately.</li>
                        <li>We reserve the right to withdraw linking permission without notice.</li>
                        <li>We may disable all or any social media features and any links at any time without notice at our discretion.</li>
                        <li>We are not responsible for the content available on any other Internet sites linked to the site.</li>
                        <li>Access to any other Internet sites linked to the Cuspyd website is at the user’s own risk.</li>
                        <li>Replies and Comments on Cuspyd Social Media Platforms:
                        <ol>
                            <li>We are under no obligation to reply to, or review any messages, information, content, or comments posted on the site by users.</li>
                            <li>We are not responsible nor liable for any comments or content posted by users on our social media.</li>
                            <li>However, Cuspyd may monitor such user posts on social media and may decline to accept and/or remove any user comments or posts that contain:
                            <ol className="alpha-list">
                                <li>Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racially, ethnically or otherwise objectionable material of any kind, including, but not limited to any material which encourages conduct that would constitute a criminal offence, give rise to civil liability, or otherwise violate any applicable local, state, national, or international law.</li>
                                <li>Advertisements or solicitations of any kind.</li>
                                <li>Messages posted by users impersonating others.</li>
                                <li>Messages containing personal information such as phone numbers, social security numbers, account numbers, addresses, or employer references.</li>
                                <li>Messages by non-spokesperson employees of Cuspyd, purporting to speak on behalf of the company.</li>
                                <li>Messages that offer unauthorized download of any copyrighted or private information.</li>
                                <li>Multiple messages placed within individual folders by the same user restating the same point.</li>
                                <li>Chain letters of any kind.</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <h5 className="bg-title">General Prohibitions</h5>
                      <ol>
                        <li>Prohibited Uses You may use the Website/app only for lawful purposes and in accordance with these Terms of Use.</li>
                        <li>You agree not to use the Website/app:
                        <ol>
                            <li>In any way that violates any applicable national, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from India or other countries).</li>
                            <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
                            <li>To send, knowingly receive, upload, download, use, or re-use any material which does not comply with the Content Standards set out in these Terms of Use.</li>
                            <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation.</li>
                            <li>To impersonate or attempt to impersonate Cuspyd, an employee of Cuspyd, another user, or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with the ones mentioned above).</li>
                            <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website/app, or which, as determined by law, may harm Cuspyd or users of the Website/app or expose them to liability.</li>
                          </ol>
                        </li>
                        <li>Additionally, you agree not to:
                        <ol>
                            <li>Use the Website/app in any manner that could disable, overburden, damage, or impair the website/app or interfere with any other party’s use of the Website/app, including their ability to engage in real-time activities through the Website.</li>
                            <li>Use any robot, spider or other automatic devices, process, or means to access the Website/app for any purpose, including monitoring or copying any material on the Website/app.</li>
                            <li>Use any manual process to monitor or copy any material on the Website/app or for any other unauthorized purpose.</li>
                            <li>Use any device, software or routine that interferes with the proper working of the Website/app.</li>
                            <li>Introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or technologically harmful to the website/app.</li>
                            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any part of the Website/app, the server/host on which the Website/app is stored, or any server/host, computer or database connected to the Website/app.</li>
                            <li>Attack the Website/app and server/host via a denial-of-service attack or a distributed denial-of-service attack.</li>
                            <li>Otherwise, attempt to interfere with the proper working of the Website/app.</li>
                          </ol>
                        </li>
                        <li>You also agree not to do any of the following:
                        <ol>
                            <li>Post, upload, publish, submit or transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships.</li>
                            <li>Take, post, upload, publish, transmit or otherwise make use of any screenshots, screen captures, reproductions, drawings, photos, videos, downloads or data of any of the images, comments or contributions to Cuspyd or any Collective Content.</li>
                            <li>Post, upload, publish, submit or transmit any Content that:
                            <ol className="alpha-list">
                                <li>Infringes, misappropriates, or violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy;</li>
                                <li>Violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability;</li>
                                <li>Is fraudulent, false, misleading or deceptive;</li>
                                <li>Is defamatory, obscene, pornographic, vulgar or offensive;</li>
                                <li>Promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;</li>
                                <li>Is violent or threatening or promotes violence or actions that are threatening to any person or entity;</li>
                                <li>Promotes illegal or harmful activities or substances.</li>
                              </ol>
                            </li>
                            <li>Use, display, mirror or frame the Website or App, or any individual element within the Website, Services or App, Dentedu Pvt Ltd, or Cuspyd’s names, any Dentedu Pvt Ltd trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without Dentedu Pvt Ltd express written consent;</li>
                            <li>Access, tamper with, or use non-public areas of the Website or App, Dentedu Pvt Ltd computer systems, or the technical delivery systems of Dentedu Pvt Ltd providers;</li>
                            <li>Attempt to probe, scan, or test the vulnerability of any Cuspyd system or network or breach any security or authentication measures;</li>
                            <li>Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Dentedu Pvt Ltd or any of Dentedu Pvt Ltd providers or any other third party (including another user) to protect the Website, Services, App or Collective Content;</li>
                            <li>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Website, Services, App or Collective Content to send altered, deceptive or false source-identifying information;</li>
                            <li>Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Website, Services, App or Collective Content;</li>
                            <li>Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Website, Services or App;</li>
                            <li>Collect or store any personally identifiable information from the Website, Services or App from other users of the Website, Services or App without their express permission;</li>
                          </ol>
                        </li>
                        <li>Dentedu Pvt Ltd will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law.</li>
                        <li>Dentedu Pvt Ltd may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.</li>
                        <li>
                        You acknowledge that
                        <ol>
                          <li>Dentedu Pvt Ltd has no obligation to monitor your access to or use of the Website, Services, App or Collective Content or to review or edit any Collective Content, but has the right to do so for the purpose of operating the Website, Services and App, to ensure your compliance with these Terms and the Privacy Policy, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body having jurisdiction.</li>
                          <li>Dentedu Pvt Ltd reserves the right, at any time and without prior notice, to remove or disable access to any Collective Content that Dentedu Pvt Ltd, at its sole discretion, considers being in violation of these Terms, the Privacy Policy, or otherwise harmful to the Website, Services or App.</li>
                        </ol>
                        </li>
                      </ol>
                      <h5 className="bg-title">Indemnity</h5>
                      <ol>
                        <li>You agree to defend, indemnify, and hold Dentedu Pvt Ltd, its officers, directors, employees and agents, harmless from, and against any claims, liabilities, damages, losses and expenses, proceedings or demands, including without limitation, reasonable legal and accounting fees, arising out of, or in any way connected with your access to, or use of the Website, Services, App or Collective Content, or your violation of these Terms.</li>
                      </ol>
                      <h5 className="bg-title">Limitation of liability</h5>
                      <ol>
                        <li>In no event shall the company, its affiliates, licensors, employees, agents, officers or directors, be liable to subscriber or any third party, for any special, punitive, incidental, indirect or consequential damages of any kind, or any damages whatsoever, including, without limitation, those resulting from loss of use, loss of data, or loss of profits, whether or not company has been advised of the possibility of such damages, and on any theory of liability, arising out of or in connection with the use of the services or of any web site referenced or linked to from the services.</li>
                        <li>Notwithstanding anything to the contrary in the foregoing, subscriber’s exclusive remedy and company and its affiliate’s entire liability, if any, for any claims arising out of these terms of service, shall be limited to the lesser of the amount paid by subscriber to Cuspyd in subscription fees during the 6-month period immediately preceding the date on which the claim or action arose.</li>
                        <li>You acknowledge and agree that
                          <ol>
                            <li>To the maximum extent permitted by law, the entire risk arising out of your access to and use of the site, services, app and collective content remains with you.</li>
                            <li>Neither Cuspyd, nor any other person or entity involved in creating, producing, or delivering the website, services, app or collective content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of, or in connection with these terms, or from the use of or inability to use the site, services, app or collective content, or from any communications, interactions or meetings with other users of the site, services or app or other persons with whom you communicate or interact as a result of your use of the site, services or app whether based on warranty, contract, delict, tort (including negligence), product liability or any other legal theory, and whether or not Cuspyd has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed in meeting its essential purpose.</li>
                          </ol>
                        </li>
                      </ol>
                      <h5 className="bg-title">Proprietary Rights and Ownership</h5>
                      <ol>
                        <li>All trademarks, service marks, logos, trade names and any other proprietary designations of Cuspyd used herein are trademarks or registered trademarks of Dentedu Pvt Ltd and its licensors or other providers of such material.</li>
                        <li>Any other trademarks, service marks, logos, trade names and any other proprietary designations are the trademarks or registered trademarks of their respective parties.</li>
                        <li>No right, title or interest in, or to the Services, or any portion thereof, is transferred to any Subscriber or Authorized End User, and all rights not expressly granted herein, are reserved by Cuspyd.</li>
                      </ol>
                      <h5 className="bg-title">Applicable Law/Jurisdiction</h5>
                      <ol>
                        <li>Except as provided in the addenda applicable to your specific jurisdiction provided with the Terms and except where mandatory law supersedes this choice of law clause, these Terms shall be governed by the laws of India.</li>
                        <li>Notices: <br/>
                        Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms, will be in a written form and given:
                        <ol>
                          <li>By Dentedu Pvt Ltd via email (in each case, to the address that you provide); or</li>
                          <li>By posting on the Website; or</li>
                          <li>Via App Notifications For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.</li>
                        </ol>
                        </li>
                        <li>This Agreement constitutes the entire understanding between the parties with respect to the subject matter hereof, and all prior agreements, representations, statements, and undertakings, oral or written, are hereby expressly superseded and cancelled.</li>
                      </ol>
                      <h5 className="bg-title">Dispute Resolution</h5>
                      <ol>
                        <li>All disputes between you and Cuspyd, arising out of, or concerning these Terms, including but without limitation any dispute as to the interpretation of these Terms, any alleged breach of these Terms, the right of a party to exercise any right or remedy herein provided which have not been settled by informal negotiations or mediation, shall be resolved by binding arbitration in accordance with the laws of India and the place of arbitration proceedings shall be conducted in either Andhra Pradesh or Telangana State.</li>
                        <li>The failure of Cuspyd to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Dentedu Pvt Ltd.</li>
                        <li>Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</li>
                        <li>If for any reason, a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.</li>
                      </ol>
                      <h5 className="bg-title">Violations</h5>
                      <ol>
                        <li>In the event that you breach any term of this agreement, Cuspyd may, at its sole discretion, terminate this agreement, your access to the site, and its provision of services to you without refund.</li>
                        <li>Cuspyd reserves the right to seek all remedies available by law and in equity, for such breaches.</li>
                      </ol>
                      <h5 className="bg-title">No Warranties</h5>
                      <ol>
                        <li>Cuspyd.com provides its services “as is” and without any warranty or condition, express, implied, or statutory. We specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose, and non-infringement.</li>
                        <li>Cuspyd does not endorse and is not responsible for the accuracy or reliability of any opinion, advice, or statement made through the site by any party other than www.Cuspyd.com.</li>
                        <li>Other than as required under consumer protection law, under no circumstance will www.Cuspyd.com be liable for any loss or damage caused by a user’s reliance on information obtained through the site. It is the responsibility of the user to evaluate the accuracy, completeness, or usefulness of any information, opinion, advice, or other content available through the site.</li>
                        <li>Please seek the advice of professionals as appropriate regarding the evaluation of any specific information, opinion, advice, or other content. The information provided on www.Cuspyd.com is not a substitute for individual patient assessment based upon the healthcare provider’s examination of each patient and consideration of laboratory data and other factors unique to the patient.</li>
                        <li>www.Cuspyd.com should be used as a tool to help medical students pass their exams.</li>
                        <li>www.Cuspyd.com reserves the right to withdraw subscriptions and the complete site if necessary. If this ever becomes necessary, users will only pay for the time of their subscription that has elapsed (on a pro-rata basis).</li>
                        <li>www.Cuspyd.com will use reasonable efforts to rectify any error that occurs on its site but does not guarantee access to the site in case of software errors/viruses/other natural or technical catastrophes that cannot be repaired in spite of a reasonable effort.</li>
                        <li>www.Cuspyd.com will use reasonable efforts to keep its site online at all times. The user understands, however, that scheduled maintenance and problems that are beyond the control of www.Cuspyd.com can cause the site to be temporarily unavailable.</li>
                      </ol>
                      <h5 className="bg-title">Terms and Conditions for the Cuspyd WhatsApp users</h5>
                      <ol>
                            <li>You are subscribing to the Cuspyd service and taking part in engaging with us over WhatsApp.</li>
                            <li>You may unsubscribe from the channel at any time.</li>
                            <li>These terms and conditions may be withdrawn/superseded/cancelled/ halted by Cuspyd at any time for any reason, without any prior notice.</li>
                            <li>You are requested to visit Cuspyd website (www.Cuspyd.com) for regular updates and changes.</li>
                            <li>You shall make sure of an appropriate network connection. The receipt of messages by Customer shall be subject to their personal network connection. Cuspyd shall not be held liable for any delay or non-receipt of any communication on WhatsApp.</li>
                            <li>Cuspyd will disclose the nature of the communication, either promotional or alerts/notification/update, on its WhatsApp channel. Cuspyd reserves the right, at its discretion, to add or remove this feature on WhatsApp.</li>
                            <li>Subscribing to WhatsApp will allow Cuspyd to send all its communication to the customer on WhatsApp. Cuspyd shall have sole discretion to stop sending these alerts to the customer on any other medium.</li>
                            <li>Customers shall not submit or transfer any content through this service that is unethical, immoral or malicious.</li>
                            <li>Under no circumstances shall Cuspyd, or its agents, affiliated companies, officers, directors, employees, and contractors be liable for any direct, indirect, punitive, incidental, special, or consequential damages that are a consequence of the inability to use this service or for receipt of any answer provided by the program running at the back-end.</li>
                            <li>The customer understands that using WhatsApp may carry extra risks and this third-party app may not be secure. Further, any message and information exchanged is subject to the risk of being read, interrupted, intercepted, or defrauded by a third party, or otherwise subject to manipulation by a third party, or involve delay in transmission. Cuspyd shall not be responsible or liable to the customer or any third party for the damages or consequences arising out of using, or in connection with this service.</li>
                            <li>Cuspyd has the right to withdraw the service anytime it deems fit.</li>
                          </ol>
                          <h5 className="bg-title">Contact Cuspyd</h5>
                          <h5 className="bg-title">These Services are operated by Dentedu Pvt Ltd. For queries related to Cuspyd terms and conditions, please write to help@Cuspyd.com</h5>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default TermsConditions;
