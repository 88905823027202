import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import sub01 from "../images/blog/sub/07/01.png";
import sub02 from "../images/blog/sub/07/02.png";
import sub03 from "../images/blog/sub/07/03.png";
import sub04 from "../images/blog/sub/07/04.png";
import sub05 from "../images/blog/sub/07/05.png";
import sub06 from "../images/blog/sub/07/06.png";
import sub07 from "../images/blog/sub/07/07.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>HOW DIGITAL EDUCATION IS A BOON FOR DENTAL STUDENTS</h2>

<p>During my BDS days, there were hardly any well-developed apps or websites for education in dentistry. Today, times have changed to our advantage. The days are gone when knowledge was just accessible through books and libraries. The power of digitalization has made information available just a click away.</p>
<img className="py-4" src={sub01} alt="" />
<p>Here are 5 ways how digital education is going to change the lives of dentists and dental students forever:</p>

<ol>
  <li><strong>Ease of Accessibility</strong>
  <img className="py-4 d-block" src={sub02} alt="" />
    <p>Through digital education, you can have 24X7 accessibility to valuable information compiled by experts in the field. It doesn’t matter if you miss a class due to health issues or circumstances out of your control. You can still be on par with your colleagues by catching up with the missed topic whenever you get time. You can use your travel time or long waiting periods for full productivity.</p>
  </li>

  <li><strong>Video lectures - pause, play, repeat!</strong>
  <img className="py-4 d-block" src={sub03} alt="" />
    <p>Can you ever hit the pause, play, or repeat button for your offline classes? Absolutely not! If you miss out on a piece of information, you lose the opportunity to learn it. Whereas, through the amazing concept of video lectures, you can always hit pause or watch a particular section multiple times till you finally understand it. You have the power to go slow or fast according to your pace of grasping knowledge.</p>
  </li>

  <li><strong>Accurate demo videos for practicals</strong>
  <img className="py-4 d-block" src={sub04} alt="" />
    <p>Didn’t understand how to complete a particular practical exercise? Now you don’t have to go through the wrath of your professors for asking the same questions again and again. Demo videos give you access to step-by-step and easy-to-understand videos on practicals and carving exercises. After a few months, if you forget the steps to carve a tooth, you can always go back to the demo video to brush up on your skills before the exams.</p>
  </li>

  <li><strong>Clear your doubts on the go</strong>
  <img className="py-4 d-block" src={sub05} alt="" />
    <p>Have you ever been in a situation when you desperately needed to clear a doubt for an important test but couldn’t do so due to non-availability of your professors after college hours? We all have been there. However, now you can ask doubts to your mentors without any hesitation even after college hours.</p>
  </li>

  <li><strong>No more boring textbooks</strong>
  <img className="py-4 d-block" src={sub06} alt="" />
    <p>Through digital education, learning can be much more fun as compared to going through the plain textbooks for hours. It is scientifically proven that the human brain is more likely to remember a piece of knowledge if it is presented in visual and audio form along with the text. You don’t have to sit for hours and make notes on important topics by referring to multiple textbooks. Digital notes compiled by the mentors make your job much easier so that you can focus more on revisions.</p>
  </li>
</ol>

<h3>DIGITAL REVOLUTION</h3>
<img className="py-4" src={sub07} alt="" />
<p>The internet is booming with information from all around the world. However, not every internet source can be trusted for its accuracy and authenticity. The upcoming app-based learning tools and websites, curated by experts, prove to have a bright future in dental education. The world is getting digitally smart, are you?</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
