import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import sub01 from "../images/blog/sub/01/01.png";
import sub02 from "../images/blog/sub/01/02.png";
import sub03 from "../images/blog/sub/01/03.png";
import sub04 from "../images/blog/sub/01/04.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5 sub-content">
              <Row>
                <Col>
                <h2>PART 1: CLINICAL CAREER OPTIONS AFTER BDS IN ACADEMICS</h2>
<p>Every dental student faces some confusion on the future career aspects after graduating as BDS. In this blog, various clinical options related to the academic field will be discussed to help you in making the right choice for yourself.</p>

<h3>CLINICAL OPTIONS AVAILABLE FOR YOU IN ACADEMICS</h3>
<ul>
  <li>MDS (Masters in Dental Surgery)</li>
  <li>Dental diplomas and hands-on courses</li>
  <li>Lecturer/Tutor in a Dental Institution</li>
  <li>Junior Residency (JRship)</li>
</ul>

<h3>1. MDS (Masters in Dental Surgery)</h3>
<img className="py-4" src={sub01} alt="" />
<p>You have an option to choose from the 9 branches of dentistry:</p>
<ul>
  <li>Prosthodontics</li>
  <li>Periodontics</li>
  <li>Pedodontics</li>
  <li>Conservative Dentistry and Endodontics</li>
  <li>Orthodontics</li>
  <li>Oral and Maxillofacial Surgery</li>
  <li>Oral Medicine and Radiology</li>
  <li>Oral Pathology</li>
  <li>Public Health Dentistry</li>
</ul>

<h4>Selection Criteria:</h4>
<ul>
  <li><strong>NEET MDS Exam:</strong> Conducted annually in December or January.</li>
  <li><strong>INI-CET Exam:</strong> Conducted twice annually.</li>
</ul>
<p><strong>Syllabus:</strong> All subjects from 1st year to 4th year BDS.<br/>
<strong>Type of Exam:</strong> Online exam consisting of MCQs.<br/>
<strong>Duration:</strong> 3 hours.<br/>
<strong>Eligibility:</strong> Any dentist who has passed the 4th year BDS University exam and completed one year of compulsory internship from a DCI-recognized institution.</p>

<h4>Types of Seats Available:</h4>
<ul>
  <li><strong>Government Seats:</strong>
    <ul>
      <li>Availed through Central or State Counseling.</li>
      <li>High competition due to limited seats, better clinical exposure, and stipend (₹50k-70k per month).</li>
      <li>Tuition fees range from ₹20k-90k annually.</li>
    </ul>
  </li>
  <li><strong>Private Seats:</strong>
    <ul>
      <li>Includes Deemed Universities and Private Institutions.</li>
      <li>Advantages: Advanced equipment and good infrastructure.</li>
      <li>Disadvantages: Lesser patient exposure and higher annual fees (~₹10 lakhs).</li>
    </ul>
  </li>
</ul>

<h4>When Should You Choose MDS?</h4>
<ul>
  <li>You plan to open your clinic.</li>
  <li>You want to practice as a specialist consultant.</li>
  <li>You are interested in teaching and academics.</li>
  <li>You aim to attain mastery in a particular branch.</li>
</ul>

<h4>How to Prepare for NEET MDS?</h4>
<ul>
  <li>Focus on building basic concepts in theory and practicals for all 4 years of BDS.</li>
  <li>Practice MCQs after completing theory preparation.</li>
  <li>Utilize coaching institutes or apps for additional guidance.</li>
</ul>

<h3>2. Dental Diplomas and Hands-On Courses</h3>
<img className="py-4" src={sub02} alt="" />
<p>If you don’t want to invest 3 years in pursuing MDS, you can do diploma and hands-on courses in a particular field to improve your clinical skills.</p>
<p><strong>Duration:</strong> Few days to months.</p>
<p><strong>Options Available:</strong></p>
<ul>
  <li>Endodontics</li>
  <li>Implants</li>
  <li>Smile Designing</li>
  <li>Orthodontics</li>
  <li>Impactions</li>
  <li>Esthetics</li>
  <li>Lasers</li>
</ul>

<h4>Things to Keep in Mind:</h4>
<ul>
  <li>Select courses according to your clinical setup requirements.</li>
  <li>Prefer DCI-approved or government-approved diplomas.</li>
  <li>Consider your mentor’s reviews before choosing a course.</li>
</ul>

<h3>3. Lecturer or Tutor in Dental College or Institution</h3>
<img className="py-4" src={sub03} alt="" />
<p>Entering the academic field allows you to teach budding dentists while learning the administrative aspects of a dental department.</p>
<ul>
  <li>Adds to your teaching experience and enhances your CV.</li>
  <li>Provides networking opportunities within your field.</li>
  <li>Keeps you updated with the latest advancements and technologies.</li>
</ul>

<h4>Things to Keep in Mind:</h4>
<ul>
  <li>Keep an eye on job openings in dental colleges.</li>
  <li>Prepare well and be confident during job interviews.</li>
  <li>Update your CV before applying for jobs.</li>
</ul>

<h3>4. Junior Residency - JRships (House Job)</h3>
<img className="py-4" src={sub04} alt="" />
<p>Conducted at reputed institutions like AIIMS, MAIDS, PGI, JIPMER, KGMU, GDCs, ESIC, etc.</p>
<p><strong>Eligibility:</strong> BDS marks &gt;60%, within 2 years of internship.<br/>
<strong>Selection Procedure:</strong> Written exams and walk-in interviews.<br/>
<strong>Duration:</strong> 6 months to 1 year.<br/>
<strong>Salary:</strong> Government institutions: ₹50k, Private: ₹15k.</p>

<h4>Things to Keep in Mind:</h4>
<ul>
  <li>JRship can only be done once in your career from any institution.</li>
  <li>Stay updated about JRship openings via newspaper ads and official websites.</li>
  <li>Gives you a chance to associate with your dream college.</li>
</ul>

<h3>Conclusion</h3>
<p>Academic clinical options help you expand your knowledge, experience, and clinical skills, setting a foundation to establish yourself as an excellent clinician or academician.</p>

                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
