export const PATH = {
  HOME: "/home",
  ABOUT: "/about",
  BLOG: "/blog",
  ClinicalCareerOptions: "/ClinicalCareerOptions",
  NonClinicalCareer: "/NonClinicalCareer",
  BdsGovernmentSector: "/BdsGovernmentSector",
  BdsAdministrative: "/BdsAdministrative",
  BdsPrivateSector: "/BdsPrivateSector",
  BdsAbroad: "/BdsAbroad",
  BoonDentalStudents: "/BoonDentalStudents",
  MdsFirstYear: "/MdsFirstYear",
  MentalHealthDentalStudents: "/MentalHealthDentalStudents",
  SurvivalGuide: "/SurvivalGuide",
  PrivacyPolicy: "/PrivacyPolicy",
  TermsandConditions: "/TermsandConditions",
  FAQS: "/Faqs",
};
