import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import Blog01 from "../images/blog/01.png";
import Blog02 from "../images/blog/02.png";
import Blog03 from "../images/blog/03.png";
import Blog04 from "../images/blog/04.png";
import Blog05 from "../images/blog/05.png";
import Blog06 from "../images/blog/06.png";
import Blog07 from "../images/blog/07.png";
import Blog08 from "../images/blog/08.png";
import Blog09 from "../images/blog/09.png";
import Blog10 from "../images/blog/10.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>HOW TO PREPARE FOR NEET MDS FROM FIRST-YEAR BDS?</h2>

<h3>What is NEET MDS?</h3>
<p>NEET MDS is a competitive national-level exam organized by the National Board of Examinations (NBE) for admission into MDS courses in various colleges all over India.</p>

<h3>Why is it important to focus on NEET MDS from the first year itself?</h3>
<p>Lots of students lack the right approach in BDS due to lack of guidance. They end up realizing it later in internship that NEET MDS preparation could have been so much easier for them if they would have approached the subjects strategically from day one itself.</p>

<h3>WHAT ARE THE BASIC STEPS OF NEET MDS PREPARATION?</h3>
<ol>
  <li><strong>STEP 1 – Mastering the Theory/ Clearing your concepts</strong></li>
  <li><strong>STEP 2 – Practicing MCQs in a timed manner</strong></li>
</ol>

<h4>STEP 1 – MASTERING THE THEORY</h4>
<p><strong>Importance of clearing basic concepts</strong>
Today, you might just have to study 3-4 subjects per year and it is easier to take out time for each subject. During internship, you’ll have to juggle 20+ subjects in one year, along with your clinical postings and other college responsibilities.</p>

<h4>How Cuspyd can help you here?</h4>
<p>Cuspyd provides you with recorded lectures for topics of all subjects included in the entire dental theory curriculum (BDS) by expert faculty. To build your concepts crystal clear, the app provides you with 3D animated anatomical and realistic models.</p>

<h4>Sources for building your concepts</h4>
<p>Gain knowledge from standard and trusted sources. Do not leave out any subject due to fear or lack of understanding.</p>

<h4>How Cuspyd can help you here?</h4>
<p>The app provides you with notes consisting of point-wise content, with multiple tables, flowcharts, and easy-to-draw illustrations to enable the student to understand better and be able to fare well in the exams.</p>

<h4>Pay attention to practical knowledge</h4>
<p>Those days are gone when these competitive exams just tested you for your theoretical knowledge. Nowadays, they focus more on the practical application aspect. Being good with your practical knowledge can give you an edge over the others.</p>

<h4>How Cuspyd can help you here?</h4>
<p>Cuspyd provides you with recorded demonstrations for practicals of all subjects in the dental curriculum. This not only includes faculty demonstrations but also, 3D animated realistic models for better visualization and understanding of complex concepts.</p>

<h4>Co-relating your knowledge</h4>
<p>Every subject is co-related to another as either a base or an application. Even the basic medical subjects have an important application on the dental subjects in the subsequent years. For example:
Physiology forms the basis of pathology and general medicine.
Anatomy forms the basis of General Surgery and Oral and Maxillofacial Surgery.</p>

<h4>How Cuspyd can help you here?</h4>
<p>It is scientifically proven that visual, audio, and video aids can help you memorize and retain knowledge better. The app provides you with Flashcards and Mnemonics which can help to reinforce key topics and their clinical applications. To maximize your co-relation power, the app gives you interactive games to make studying and co-relation a fun process.</p>

<h4>STEP 2 – SOLVING THE MCQs</h4>
<p>Now when you feel confident enough with your theory concepts, you can move on to solving MCQs.</p>

<h4>How much time should you give for MCQs per day?</h4>
<p>30-60 minutes per day is enough for a BDS student. Start with 10 MCQs at a time and eventually increase your target.</p>

<h4>How Cuspyd can help you with practicing MCQs?</h4>
<p>After every topic you study, Cuspyd provides you with MCQs for self-assessment, revision, and for competitive exam guidance. This helps in familiarization by exposing you to questions of the same pattern as asked in various competitive exams from that topic.</p>

<h3>GETTING AHEAD IN THE RIGHT DIRECTION</h3>
<p>In this highly competitive world, if you are focused from the beginning of your journey, you will definitely have an advantage on your side. By managing your time and taking care of your priorities, you can easily start working in the right direction for your successful future.</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
