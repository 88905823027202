import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import sub01 from "../images/blog/sub/04/01.png";
import sub02 from "../images/blog/sub/04/02.png";
import sub03 from "../images/blog/sub/04/03.png";
import sub04 from "../images/blog/sub/04/04.png";
import sub05 from "../images/blog/sub/04/05.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>NON CLINICAL CAREER OPTIONS AFTER BDS – ADMINISTRATIVE AND CORPORATE SECTOR</h2>
<p>If you want to explore outside the healthcare sector, there are several non-clinical job opportunities for dentists in the administrative and corporate sector. These career opportunities may help you be a part of major hospital chains and MNCs.</p>

<h3>NON CLINICAL CAREER OPTIONS IN ADMINISTRATIVE AND CORPORATE SECTOR -</h3>
<ul>
  <li>Master in Public Health (MPH)</li>
  <li>Master in Hospital Administration (MHA)</li>
  <li>Master in Business Administration (MBA)</li>
  <li>Insurance sector</li>
</ul>

<h3>1. Master in Public Health (MPH)</h3>
<img className="py-4" src={sub01} alt="" />
<h4>What is MPH?</h4>
<p>MPH is a course where you can learn how to tackle the issues that affect our communities, educate the public about best healthcare practices, work with people from different sectors, and play an active role in making a difference in people’s lives on a large scale.</p>

<h4>What are the various MPH programs available?</h4>
<ul>
  <li>Community Health Promotion</li>
  <li>Epidemiology</li>
  <li>Public Health Administration & Policy</li>
  <li>Public Health Nutrition</li>
  <li>Public Health Data Science</li>
  <li>Maternal & Child Health</li>
  <li>Environmental Health</li>
  <li>Public Health Practice</li>
</ul>

<h4>What are the job opportunities you can get as a Public Health Practitioner?</h4>
<ul>
  <li>Health Educator</li>
  <li>Health Engagement Marketing Manager</li>
  <li>Epidemiologist</li>
  <li>Community outreach coordinator</li>
  <li>Public Policy Coordinator</li>
  <li>Substance Abuse Counselor</li>
  <li>Research Coordinator</li>
  <li>Clinical Dietician</li>
  <li>Healthcare Administrator</li>
  <li>Environmental Health Scientist</li>
</ul>

<h4>Duration:</h4>
<p>It is a two-year postgraduate interdisciplinary program.</p>

<h4>PROS:</h4>
<ul>
  <li>Opportunity to be a part of NGOs and government organizations like WHO and UNICEF.</li>
  <li>Improving health on a community level.</li>
</ul>

<h4>CONS:</h4>
<ul>
  <li>Needs significant commitment for classes, research, and assignments.</li>
  <li>Needs financial investment.</li>
</ul>

<h3>2. Masters in Hospital Administration (MHA)</h3>
<img className="py-4" src={sub02} alt="" />
<h4>What is MHA?</h4>
<p>In this course, you will be dealing with administration, business strategies, marketing, finances, healthcare delivery, accounting, economics, healthcare policy, and law.</p>

<h4>What are the job opportunities you can get?</h4>
<ul>
  <li>Director of hospital operations</li>
  <li>Practice manager</li>
  <li>Director of patient safety</li>
  <li>Clinic Manager</li>
  <li>Floor manager</li>
  <li>Director of business development</li>
  <li>Chief nursing officer</li>
  <li>Chief executive officer</li>
</ul>

<h4>Duration:</h4>
<p>MHA is a two-year, full-time program.</p>

<h4>PROS:</h4>
<ul>
  <li>Development of skills to navigate a complex healthcare system.</li>
  <li>Opportunities to learn leadership and networking skills.</li>
</ul>

<h4>CONS:</h4>
<ul>
  <li>Limited career options.</li>
  <li>Needs financial investment and time commitment.</li>
</ul>

<h3>3. MASTER OF BUSINESS ADMINISTRATION (MBA)</h3>
<img className="py-4" src={sub03} alt="" />
<h4>What is MBA?</h4>
<p>This field is concerned with the business administration of healthcare facilities. It imparts knowledge of various economical, social, legal, and ethical issues pertaining to hospital administration.</p>

<h4>Branches of MBA which can be pursued after BDS:</h4>
<ul>
  <li>MBA in Health Management</li>
  <li>MBA in Clinical Research Management</li>
  <li>MBA in Healthcare Management</li>
  <li>MBA in Public Health Management</li>
  <li>MBA in Pharmaceutical Management</li>
  <li>MBA in Healthcare Management Systems</li>
  <li>MBA in Pharmacy</li>
</ul>
<img className="py-4" src={sub04} alt="" />

<h4>Other Opportunities for BDS graduates:</h4>
<ul>
  <li>MBA in Marketing</li>
  <li>MBA in Finance</li>
  <li>MBA in Human Resources</li>
  <li>MBA in Operations Management</li>
</ul>

<h4>Job Opportunities:</h4>
<ul>
  <li>Hospital Administrator</li>
  <li>Healthcare Information Manager</li>
  <li>Physician Relations Liaison</li>
  <li>Claims Manager</li>
  <li>Purchasing Manager</li>
  <li>Healthcare insurance companies</li>
  <li>Medical Tourism</li>
</ul>

<h4>Duration:</h4>
<p>2 years</p>

<h4>PROS:</h4>
<ul>
  <li>Enhanced knowledge on business and administrative skills.</li>
  <li>Opportunities in dental administration, consulting, and entrepreneurship.</li>
</ul>

<h4>CONS:</h4>
<ul>
  <li>Needs commitment of time and resources for classes, research, and assignments.</li>
  <li>Needs financial investment.</li>
</ul>

<h3>4. INSURANCE SECTOR</h3>
<img className="py-4" src={sub05} alt="" />
<h4>How can you work in the insurance sector?</h4>
<p>In this field, several companies offer dental insurance as one of their products and hire qualified dentists for research, claim reviews, and even administrative tasks. You can work in the medical insurance sector or the general insurance sector.</p>

<h4>Job Opportunities:</h4>
<ul>
  <li>Insurance examiners</li>
  <li>Insurance appraisers</li>
  <li>Auditor</li>
  <li>Actuary</li>
  <li>Investigator</li>
  <li>Claims handler</li>
  <li>Sales and marketing</li>
</ul>

<h4>Duration:</h4>
<ul>
  <li>Diploma and Certificate courses – 6 months to one year</li>
  <li>Masters in Insurance Management – 1 to 2 years</li>
</ul>

<h4>PROS:</h4>
<ul>
  <li>Development of new skills and professional growth.</li>
  <li>Stable source of employment and income.</li>
</ul>

<h4>CONS:</h4>
<ul>
  <li>Requires extensive knowledge and training on policies, regulations, and business operations.</li>
  <li>Emphasis of financial incentives over clinical outcomes.</li>
</ul>

<h2>CONCLUSION –</h2>
<p>The administrative and corporate sectors offer dentists an opportunity to explore non-traditional career paths while utilizing their skills and knowledge to improve the quality of healthcare.</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
