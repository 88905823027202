import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import Blog01 from "../images/blog/01.png";
import Blog02 from "../images/blog/02.png";
import Blog03 from "../images/blog/03.png";
import Blog04 from "../images/blog/04.png";
import Blog05 from "../images/blog/05.png";
import Blog06 from "../images/blog/06.png";
import Blog07 from "../images/blog/07.png";
import Blog08 from "../images/blog/08.png";
import Blog09 from "../images/blog/09.png";
import Blog10 from "../images/blog/10.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import Button from "react-bootstrap/Button";

const Blog = () => {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page blog-sub-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    <Button className="btn-arrow" variant="primary" href={PATH.BLOG}><i class="bi bi-arrow-left-short"></i> Blogs</Button>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
          <div className="gradiant-bg px-5 py-5 sub-content">
            <Row>
              <Col>
              <h2>FROM SCHOOL TO BDS COLLEGE: SURVIVAL GUIDE FOR 1st YEAR STUDENTS</h2>

<p>We all studied for NEET with immense dedication and hard work, dreaming of cracking a rank good enough to place the ‘Doctor’ prefix in front of our names in the future ahead.</p>

<p>And, voila! Everything happened perfectly and here you are joining your dental school in the first year with lots of hopes, dreams, and aspirations to become the best dentist out there.</p>

<p>But…</p>

<p>College life turned out to be so different from school life. Lost and confused, you wished there was some help in the form of guidance from a trusted source to help you cope with it all.</p>

<p>Sounds like you? Don’t worry, you are not alone in this. Even I went through the same during my BDS days. I will be throwing some light on the 5 major issues I faced and how I coped with them in the upcoming section.</p>

<h3>HOW SCHOOL LIFE IS DIFFERENT FROM COLLEGE LIFE IN BDS?</h3>

<h4>Extensive syllabus</h4>
<p>In school, we all had a specific textbook for each subject and studying for exams was a straight pathway. In BDS, there are multiple books for each subject ranging from foreign authors to Indian authors – there is no golden book that has it all. You try to make notes from multiple books just before exams and end up getting more confused and overwhelmed with information.</p>

<h5>WHAT CAN BE DONE –</h5>
<p>It’s not the number of books you study but the number of times you revise, that matters. Develop a habit of regularly making exam-based notes as a compilation of your lecture and textbook notes. It will make your job easier during exam time and you can invest your time in multiple focused revisions.</p>

<h4>Fear of your professors</h4>
<p>College professors seem much more strict and scary as compared to your school teachers. Most students hesitate to ask doubts and questions fearing they might get scolded in front of the professor. Knowing that they have the complete power to either fail you or pass you in your final exams, you end up pretending that you understood everything in the class, even if you didn’t.</p>

<h5>WHAT CAN BE DONE –</h5>
<p>No professor will ever refuse to help a student if they are showing sincere efforts. Have a good rapport with your staff, be regular in classes, try to study before class, and answer the lecture questions. If you are too hesitant to ask your doubts in the class itself, try to approach your professors after the class.</p>

<h4>Test of your practical skills and handwork</h4>
<p>Suddenly you are handed over a carver and a wax block and you are expected to carve a perfect tooth out of it, keeping in mind all the anatomic details and technicality. Usually, the demonstration is given with a group of students and if you’re standing at the back or if you fall sick on that particular day, your opportunity to learn is gone! No professor will exclusively give a demonstration again for just one student. It’s difficult to visualize the steps given in the books and apply them practically.</p>

<h5>WHAT CAN BE DONE –</h5>
<p>Attend all demonstration classes in a focused manner, and take notes if necessary. “PRACTICE, PRACTICE, PRACTICE” Your competition is not your batchmate but your previous carving. Try to improve and refine your skills with every new attempt. Read the textbooks carefully and watch online videos to understand the intricacies of tooth anatomy instead of carving blindly.</p>

<h4>Lack of approach to seniors</h4>
<p>Being a first year, it is always scary to interact with the senior batches, not knowing how they will react. It takes time to build up meaningful connections with seniors who can guide you. But, till that happens, most of your precious first year is already gone.</p>

<h5>WHAT CAN BE DONE –</h5>
<p>Don’t hesitate to approach your seniors for help. Since they have gone through this phase, they understand what you’re going through. Eventually, you’ll end up with amazing friends from your senior batches who can help and guide you.</p>

<h4>Confusion about how to prepare for university exams</h4>
<p>University dental exams are different from school-level exams, where the syllabus is vast in comparison to the latter. Since, as a first year student, you are going to attempt the University exams for the first time, it becomes a little confusing to understand how to approach the preparations effectively.</p>

<h5>WHAT CAN BE DONE –</h5>
<ul>
  <li>Collect previous years’ questions from the beginning itself.</li>
  <li>Take your internal exams seriously as they give you real-time practice for the final examination.</li>
  <li>Focus more on a clear presentation of your answers including flow charts, diagrams, and tables. This will give your answers an extra edge.</li>
  <li>Build your concepts as you go through the topics. It will give you an advantage in theory and viva.</li>
</ul>

<h3>All in all… YOU GOT THIS!!</h3>
<p>First year can prove to be challenging for most students since it takes time and effort to adjust to the upgrade from school life to college life. Mostly, the difficulties faced are due to a lack of guidance and direction. With a proper approach, you can kick start your dental career from the first year itself!</p>

              </Col>
            </Row>
          </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default Blog;
